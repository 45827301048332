import {
  Button,
  Form,
  Input,
  InputNumber,
  Popconfirm,
  Select,
  Typography
} from 'antd';
import {
  Create,
  ListButton,
  RefreshButton,
  useForm,
  useSelect
} from '@refinedev/antd';
import { PlusOutlined } from '@ant-design/icons';

const { Text } = Typography;

export const FranchiseManagementCreate = () => {
  const { selectProps: getaweysSelectProps } = useSelect({
    resource: 'franchises',
    optionLabel: 'name',
    optionValue: 'id'
  });

  const { selectProps: getaweysRulesSelectProps } = useSelect({
    resource: 'offers/gateways/rules',
    optionLabel: 'name',
    optionValue: 'id'
  });

  const { selectProps: fiscaTemplatesSelectProps } = useSelect({
    resource: 'fiscal_templates',
    optionLabel: 'name',
    optionValue: 'id'
  });

  const { formProps, saveButtonProps } = useForm();

  return (
    <Create
      title="Criar Regra de Franquia"
      saveButtonProps={saveButtonProps}
      headerButtons={() => (
        <>
          <ListButton />
          <RefreshButton />
        </>
      )}
    >
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Gateway"
          name="gateway_id"
          rules={[{ required: true }]}
        >
          <Select {...getaweysSelectProps} />
        </Form.Item>
        <Form.Item label="Regra" name={'rule_id'} rules={[{ required: true }]}>
          <Select {...getaweysRulesSelectProps} />
        </Form.Item>
        <Form.Item
          label="Template Fiscal"
          name={'fiscal_template_id'}
          rules={[{ required: true }]}
        >
          <Select {...fiscaTemplatesSelectProps} />
        </Form.Item>
        <Form.Item
          label="Marketplace Fee"
          name={['marketplace_fee', 'percentage']}
          rules={[{ required: true }]}
        >
          <InputNumber min={0} max={100} />
        </Form.Item>
        <Text
          style={{
            fontSize: '14px',
            fontWeight: '500',
            display: 'flex',
            paddingBottom: '8px'
          }}
        >
          <span
            style={{
              fontSize: '10px',
              marginInlineEnd: '4px',
              color: '#ef4444'
            }}
          >
            *
          </span>
          Faixas de CEP
        </Text>
        <Form.List name="ranges">
          {(fields, { add, remove }) => (
            <div
              style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}
            >
              {fields.map(({ key, name, ...restField }) => (
                <div
                  key={key}
                  style={{
                    backgroundColor: '#f9fafb',
                    padding: '16px',
                    borderRadius: '8px'
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      gap: '8px',
                      justifyContent: 'space-between'
                    }}
                  >
                    <Text
                      style={{ fontSize: '14px', fontWeight: 500 }}
                    >{`Faixa de CEP ${name + 1}`}</Text>
                    <span>
                      <Popconfirm
                        title={`Você realmente quer deletar a Faixa de CEP ${
                          name + 1
                        }`}
                        okText="Sim"
                        cancelText="Não"
                        onConfirm={() => remove(name)}
                      >
                        <Button size="small" type="default" danger>
                          Remover
                        </Button>
                      </Popconfirm>
                    </span>
                  </div>
                  <Form.Item
                    {...restField}
                    style={{ marginBottom: '8px' }}
                    normalize={value => value.replace(/\D/g, '').slice(0, 8)}
                    key={key}
                    label={
                      <label
                        style={{
                          fontWeight: 400,
                          fontSize: '12px'
                        }}
                      >
                        Inicial
                      </label>
                    }
                    name={[name, 'initial']}
                    rules={[
                      {
                        required: true,
                        message: 'Campo obrigatório'
                      },
                      { len: 8, message: 'CEP deve ter 8 dígitos' }
                    ]}
                  >
                    <Input placeholder="CEP do endereço (somente números)" />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    style={{ marginBottom: 'none' }}
                    normalize={value => value.replace(/\D/g, '').slice(0, 8)}
                    key={key}
                    label={
                      <label
                        style={{
                          fontWeight: 400,
                          fontSize: '12px'
                        }}
                      >
                        Final
                      </label>
                    }
                    name={[name, 'final']}
                    rules={[
                      {
                        required: true,
                        message: 'Campo obrigatório'
                      },
                      { len: 8, message: 'CEP deve ter 8 dígitos' }
                    ]}
                  >
                    <Input placeholder="CEP do endereço (somente números)" />
                  </Form.Item>
                </div>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  icon={<PlusOutlined />}
                  style={{ width: '100%' }}
                >
                  Adicionar CEPs
                </Button>
              </Form.Item>
            </div>
          )}
        </Form.List>
      </Form>
    </Create>
  );
};
