import { IResource } from 'interfaces/resources';
import { generateResources } from 'utils/GenerateResouces';

const resource: IResource = {
  name: 'professor_area/notice_boards',
  label: 'Mural de avisos',
  actions: ['show', 'list', 'create', 'delete'],
  parentName: 'professor_area',
  onlyPermission: true
};

export default generateResources(resource);
