import React from 'react';
import { Avatar, Form, Input, Modal, Typography } from 'antd';
import { IAgent } from 'interfaces/agents';
import { IProfessorAreaClassroom } from 'interfaces/professor_area_classrooms';
import { getInitialsName } from 'utils/get-initials-name';
import { useCreate, useGetIdentity, useModal } from '@refinedev/core';

interface modalProps {
  modalProps: ReturnType<typeof useModal>;
  classroom: IProfessorAreaClassroom;
}

export const CreateTopicModal: React.FC<modalProps> = ({
  modalProps,
  classroom
}) => {
  const [form] = Form.useForm();
  const { data: user } = useGetIdentity<IAgent>();
  const { mutateAsync } = useCreate();

  const handleCreate = () => {
    form.validateFields().then(values => {
      mutateAsync({
        resource: 'professor_area/forums',
        values: {
          classroom_id: classroom.id,
          title: values.title,
          post_attributes: values.post_attributes
        },
        successNotification: () => ({
          type: 'success',
          description: 'Sucesso!',
          message: 'Forum criado com sucesso.'
        }),
        errorNotification: () => ({
          type: 'error',
          description: 'Ops!',
          message: 'Não foi possível criar este forum. Tente novamente.'
        })
      })
        .then(() => {
          form.resetFields();
          modalProps.close();
        })
        .catch();
    });
  };

  return (
    <Modal
      title="Novo tópico"
      width={800}
      open={modalProps.visible}
      onOk={handleCreate}
      onCancel={modalProps.close}
      okText="Criar tópico"
      cancelText="Voltar"
      centered
    >
      <Form
        form={form}
        initialValues={{
          classroom: classroom.code,
          discipline: classroom.discipline_name
        }}
      >
        <Form.Item
          name="author"
          label={
            <Typography.Text type="secondary" className="!m-0">
              Autor
            </Typography.Text>
          }
          colon={false}
          labelAlign="left"
          labelCol={{ className: 'w-[125px]' }}
        >
          <Avatar className="bg-[#a855f7]/10 text-[#9333ea] mr-2" size={40}>
            {user ? getInitialsName(user?.name) : 'UN'}
          </Avatar>
          <Typography.Text strong className="!m-0">
            {user?.name}
          </Typography.Text>
        </Form.Item>

        <Form.Item
          name="classroom"
          label={
            <Typography.Text type="secondary" className="!m-0">
              Turma
            </Typography.Text>
          }
          colon={false}
          labelAlign="left"
          labelCol={{ className: 'w-[125px]' }}
        >
          <Input disabled />
        </Form.Item>

        <Form.Item
          name="discipline"
          label={
            <Typography.Text type="secondary" className="!m-0">
              Discíplina
            </Typography.Text>
          }
          labelAlign="left"
          colon={false}
          labelCol={{ className: 'w-[125px]' }}
        >
          <Input disabled />
        </Form.Item>

        <Form.Item
          name="title"
          label={
            <Typography.Text type="secondary" className="!m-0">
              Título do tópico
            </Typography.Text>
          }
          labelAlign="left"
          colon={false}
          labelCol={{ className: 'w-[125px]' }}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name={['post_attributes', 'text']}
          label={
            <Typography.Text type="secondary" className="!m-0">
              Texto
            </Typography.Text>
          }
          labelAlign="left"
          colon={false}
          labelCol={{ className: 'w-[125px]' }}
        >
          <Input.TextArea />
        </Form.Item>
      </Form>
    </Modal>
  );
};
