import clsx from 'clsx';
import { ReactNode } from 'react';
import { Space, Typography } from 'antd';

interface ShowFieldDataProps {
  label: ReactNode;
  value: ReactNode;
  className?: string;
}

export const ShowFieldData = ({
  label,
  value,
  className
}: ShowFieldDataProps) => {
  return (
    <Space
      direction="vertical"
      className={clsx('w-full whitespace-pre-line', className)}
    >
      <Typography.Text className="font-medium">{label}</Typography.Text>
      <Typography.Text>{value}</Typography.Text>
    </Space>
  );
};
