import { Card, Space, Tag, Typography } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import { Show } from '@refinedev/antd';
import { TabList } from './components/TabList';
import { useShow } from '@refinedev/core';
import type { IProfessorAreaClassroom } from 'interfaces/professor_area_classrooms';

const { Title, Text } = Typography;

export const ClassroomShow = () => {
  const { queryResult } = useShow<IProfessorAreaClassroom>();
  const { data, isLoading } = queryResult;
  const record = data?.data;

  return (
    <Show
      breadcrumb={false}
      isLoading={isLoading}
      headerProps={{
        title: false
      }}
      goBack={<GoBack />}
      headerButtonProps={{
        hidden: true
      }}
      contentProps={{
        className: '!bg-transparent !shadow-none',
        styles: {
          body: {
            padding: 0,
            backgroundColor: 'transparent'
          }
        }
      }}
    >
      {record && (
        <>
          <Card className="!mb-4">
            <Title level={3}>{record.discipline_name}</Title>
            <Space className="!gap-1">
              <Tag>{record?.code}</Tag>
              <Tag>
                {record?.academic_year}.{record?.academic_period}
              </Tag>
            </Space>
          </Card>

          <TabList record={record} />
        </>
      )}
    </Show>
  );
};

const GoBack = () => (
  <Space className="items-center !text-neutral-500">
    <LeftOutlined />
    <Text className="!text-neutral-500 font-normal">
      Voltar para minhas turmas
    </Text>
  </Space>
);
