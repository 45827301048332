import {
  DatePicker,
  Form,
  FormProps,
  Input,
  Modal,
  ModalProps,
  Select,
  Space,
  Typography
} from 'antd';
import { FormItem } from './FormItem';
import { ICreateInteractiveClass } from 'interfaces/professor_area/interactiveClass';
import { IProfessor } from 'interfaces/professor';
import { getTimeOptions } from 'services/professor_area/interactive-classes';
import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useSelect } from '@refinedev/antd';

interface CreateClassModalProps {
  modalProps: ModalProps;
  formProps: FormProps;
}

export const CreateClassModal = ({
  modalProps,
  formProps
}: CreateClassModalProps) => {
  const { id } = useParams();
  const { selectProps: professorSelectProps } = useSelect<IProfessor>({
    resource: 'professors',
    optionLabel: (item: IProfessor) => `${item.name} - (${item.email})`,
    optionValue: 'id',
    onSearch: value => [
      {
        field: 'filter[by_name]',
        operator: 'eq',
        value
      }
    ]
  });

  const handleFinish = useCallback(
    (values: ICreateInteractiveClass) => {
      const valueWithClassroomId = {
        ...values,
        classroom_ids: [id]
      };

      formProps?.onFinish?.(valueWithClassroomId);
    },
    [id, formProps]
  );

  return (
    <Modal
      {...modalProps}
      title="Nova aula interativa"
      okText="Agendar aula"
      cancelText="Voltar"
    >
      <Form {...formProps} onFinish={handleFinish}>
        <FormItem
          name="title"
          label="Título da aula"
          rules={[{ required: true, message: 'O título é obrigatório' }]}
        >
          <Input />
        </FormItem>

        <FormItem
          name="description"
          label="Descrição"
          labelCol={{ className: 'w-[150px] p-2' }}
        >
          <Input.TextArea placeholder="Adicionar descrição" />
        </FormItem>

        <Space className="flex !items-start" direction="horizontal">
          <FormItem
            name="start_time"
            label="Data (GMT-03:00)"
            rules={[{ required: true, message: 'A data é obrigatória' }]}
          >
            <DatePicker
              showTime
              format={'DD/MM/YYYY HH:mm'}
              placeholder="dd/mm/aaaa hh:mm"
            />
          </FormItem>
          <Space className="mt-1">
            <Typography.Text className="ml-2 !text-[#00000073]">
              Agendar com 24 horas de antecedência
            </Typography.Text>
          </Space>
        </Space>

        <FormItem
          label="Duração"
          name="duration_in_minutes"
          rules={[{ required: true, message: 'A duração é obrigatória' }]}
        >
          <Select>
            {getTimeOptions().map(option => (
              <Select.Option key={option.value} value={option.value}>
                {option.label}
              </Select.Option>
            ))}
          </Select>
        </FormItem>

        <FormItem
          name="professor_ids"
          label="Professor"
          rules={[{ required: true, message: 'O professor é obrigatório' }]}
          labelCol={{ className: 'w-[150px]' }}
          labelAlign="left"
        >
          <Select {...professorSelectProps} mode="multiple" />
        </FormItem>
      </Form>
    </Modal>
  );
};
