import { Button, FormProps, Modal, ModalProps, Space } from 'antd';
import { FormNoticeBoard } from './Form';
import { IProfessorAreaNoticeBoardPost } from 'interfaces/professor_area/notice_boards';
import { toSaoPauloTimeZone } from 'services/date';
import { useMemo } from 'react';

interface ModalCreateNoticeBoardProps {
  modalProps: ModalProps;
  formProps: FormProps<IProfessorAreaNoticeBoardPost>;
  isLoading: boolean;
  onCloseModal: () => void;
  onRemoveNotice: (noticeId: string) => void;
}

export const ModalShowNoticeBoard = ({
  formProps,
  modalProps,
  onCloseModal,
  onRemoveNotice
}: ModalCreateNoticeBoardProps) => {
  const getInitialValues = useMemo(() => {
    const initialValues = { ...formProps.initialValues };

    initialValues.starts_at = initialValues?.starts_at
      ? toSaoPauloTimeZone(initialValues?.starts_at)
      : undefined;

    return initialValues;
  }, [formProps.initialValues]);

  return (
    <Modal
      {...modalProps}
      title="Aviso"
      width={700}
      style={{ paddingTop: 20, paddingBottom: 20 }}
      centered
      afterClose={() => {
        formProps.form?.resetFields();
      }}
      footer={
        <Space className="w-full flex justify-between">
          <Space>
            <Button
              type="text"
              danger
              onClick={() =>
                onRemoveNotice(formProps.form?.getFieldValue('id'))
              }
              size="large"
            >
              Excluir aviso
            </Button>
          </Space>
          <Space className="justify-self-end">
            <Button onClick={onCloseModal} size="large">
              Voltar
            </Button>
          </Space>
        </Space>
      }
    >
      <FormNoticeBoard
        formProps={formProps}
        initialValues={getInitialValues}
        isShow={true}
      />
    </Modal>
  );
};
