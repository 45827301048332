import { IProfessorAreaInteractiveClass } from 'interfaces/professor_area_interactive_class';
import { RightOutlined } from '@ant-design/icons';
import { Space, Typography } from 'antd';
import { formatDate } from 'services/date';

type InteractiveClassItemProps = {
  onShowModal?: (id: string) => void;
} & IProfessorAreaInteractiveClass;

export const InteractiveClassItem = ({
  onShowModal,
  ...item
}: InteractiveClassItemProps) => {
  const handleOpenModal = (): void => {
    onShowModal?.(item.id);
  };

  return (
    <Space
      className="group w-full p-4 hover:cursor-pointer hover:bg-neutral-100 hover:rounded-lg items-center justify-between transition"
      onClick={handleOpenModal}
    >
      <Space>
        <div className="flex flex-col bg-neutral-100 rounded-lg p-2 text-center">
          <Typography.Text className="!text-sm">
            {formatDate(item.start_time, 'DD')}
          </Typography.Text>
          <Typography.Text className="!text-xs capitalize">
            {formatDate(item.start_time, 'MMM')}
          </Typography.Text>
        </div>

        <div className="flex-1 flex flex-col">
          <Typography.Text className="!text-md">{item.title}</Typography.Text>
          <Typography.Text className="!text-neutral-500">
            {formatDate(item.start_time, 'DD/MM/YYYY HH:mm')}
          </Typography.Text>
        </div>
      </Space>

      <RightOutlined className="opacity-0 group-hover:opacity-100 transition" />
    </Space>
  );
};
