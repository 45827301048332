import React, { useCallback, useState } from 'react';
import { Avatar, Dropdown, MenuProps, Modal, Typography } from 'antd';
import {
  DeleteOutlined,
  EditOutlined,
  EllipsisOutlined,
  ExclamationCircleOutlined
} from '@ant-design/icons';
import { HybridInput } from 'pages/academic/forum/components/HybridInput';
import { IAgent } from 'interfaces/agents';
import { IProfessorAreaPosts } from 'interfaces/professor_area/forums';
import { MenuInfo } from 'rc-menu/lib/interface';
import { Stack } from '@qcx/ui';
import { TagField } from '@refinedev/antd';
import { formatDate } from 'services/date';
import { getInitialsName } from 'utils/get-initials-name';
import {
  useCreate,
  useDelete,
  useGetIdentity,
  useUpdate
} from '@refinedev/core';
import { useParams } from 'react-router-dom';

const { confirm } = Modal;

const items: MenuProps['items'] = [
  {
    key: 'edit',
    label: 'Editar mensagem',
    icon: <EditOutlined />
  },
  {
    key: 'close',
    label: 'Excluir mensagem',
    icon: <DeleteOutlined />
  }
];

export const PostItem: React.FC<{
  enableDelete?: boolean;
  postData: IProfessorAreaPosts;
  inputMode?: boolean;
  refetch: () => void;
}> = ({ enableDelete, inputMode, postData, refetch }) => {
  const { id } = useParams();
  const { data: user } = useGetIdentity<IAgent>();
  const [editTitle, setEditTitle] = useState<boolean>(false);
  const { mutateAsync: editMutation } = useUpdate();
  const { mutateAsync: createMutation } = useCreate();
  const { mutateAsync: deleteMutation } = useDelete();

  const handleStateChange = useCallback(() => {
    setEditTitle(!editTitle);
  }, [editTitle]);

  const handleDeletePost = useCallback(() => {
    confirm({
      title: 'Deseja excluir a mensagem?',
      content: 'Essa ação não pode ser revertida',
      icon: <ExclamationCircleOutlined style={{ color: 'red' }} />,
      okButtonProps: { type: 'primary', danger: true },
      okText: 'Excluir mensagem',
      cancelText: 'Voltar',
      onOk: () => {
        deleteMutation({
          resource: `professor_area/forums/${id}/posts`,
          id: postData.id,
          successNotification: () => ({
            type: 'success',
            description: 'Sucesso!',
            message: 'Post excluido com sucesso'
          }),
          errorNotification: () => ({
            type: 'error',
            description: 'Ops!',
            message: 'Não foi possível excluir este post. Tente novamente.'
          })
        })
          .then(() => {
            refetch();
          })
          .catch();
      }
    });
  }, [postData, deleteMutation, refetch, id]);

  const parsedDropdonwItems = enableDelete ? items : [...items].slice(0, -1);

  const handleCreate = useCallback(
    (value: string) => {
      createMutation({
        resource: `professor_area/forums/${id}/posts`,
        values: {
          text: value
        },
        successNotification: () => ({
          type: 'success',
          description: 'Tudo certo!',
          message: 'Post criado com sucesso'
        }),
        errorNotification: () => {
          return {
            description: 'Algo deu errado',
            message: 'Não foi possível criar o post',
            type: 'error'
          };
        }
      }).then(() => {
        refetch();
      });
    },
    [refetch, createMutation, id]
  );

  const handleEdit = useCallback(
    (value: string) => {
      if (id) {
        editMutation({
          resource: `professor_area/forums/${id}/posts`,
          id: postData.id,
          values: {
            text: value
          },
          successNotification: () => ({
            type: 'success',
            description: 'Tudo certo!',
            message: 'Post editado com sucesso'
          }),
          errorNotification: () => {
            return {
              description: 'Algo deu errado',
              message: 'Não foi possível editar o post',
              type: 'error'
            };
          }
        }).then(() => {
          handleStateChange();
          refetch();
        });
      }
    },
    [editMutation, handleStateChange, refetch, id, postData]
  );

  const handleDropdown = useCallback(
    (event: MenuInfo) => {
      if (event.key === 'edit') {
        handleStateChange();
      } else {
        handleDeletePost();
      }
    },
    [handleStateChange, handleDeletePost]
  );

  return (
    <Stack flow="column" className="mr-[240px]">
      <Stack className="flex items-center justify-between w-full">
        <Stack className="items-center w-full gap-2">
          <Avatar size={40} className="bg-[#a855f7]/10 text-[#9333ea] mr-2">
            {getInitialsName(postData.author.name)}
          </Avatar>
          <Typography.Title level={5} className="!m-0">
            {postData.author.name}
          </Typography.Title>
          {postData.author_type === 'Admin::Agent' && (
            <TagField color="orange" value="Professor" />
          )}
          {!inputMode && (
            <>
              <Typography.Text type="secondary" className="text-xl">
                •
              </Typography.Text>
              <Typography.Text type="secondary">
                {formatDate(postData.created_at, 'DD/MM/YYYY h[h]mm')}
              </Typography.Text>
            </>
          )}
        </Stack>
        {postData.author_id === user?.id && !inputMode && (
          <Dropdown
            menu={
              {
                items: parsedDropdonwItems,
                onClick: handleDropdown
              } as MenuProps
            }
            placement="bottomLeft"
          >
            <EllipsisOutlined />
          </Dropdown>
        )}
      </Stack>
      <Stack className="bg-black/[.06] p-4 ml-14 rounded-lg">
        <HybridInput
          showInput={inputMode ?? editTitle}
          value={inputMode ? '' : postData.text}
          handleState={handleStateChange}
          handleMutation={inputMode ? handleCreate : handleEdit}
        >
          {inputMode ? '' : postData.text}
        </HybridInput>
      </Stack>
    </Stack>
  );
};
