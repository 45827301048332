import React from 'react';
import { DeleteButton, ShowButton, TagField, TextField } from '@refinedev/antd';
import { IDiscussionThread } from 'interfaces/discussion_threads';
import { ITeacherDiscussionThread } from 'interfaces/teacher_discussion_threads';
import { Space, Table, TableProps } from 'antd';
import { formatDate } from 'services/date';
import { tagColorByStatus } from 'utils/statusColorMapping';
import { translateDiscussionStatus } from '..';

interface DiscussionThreadsTableProps {
  tableProps: TableProps<ITeacherDiscussionThread>;
}

export const DiscussionThreadsTable = ({
  tableProps
}: DiscussionThreadsTableProps) => {
  return (
    <Table
      {...tableProps}
      rowKey="id"
      pagination={{
        hideOnSinglePage: true
      }}
    >
      <Table.Column
        dataIndex="post_text"
        key="post_text"
        title="Dúvida"
        render={value => <TextField value={value} />}
      />
      <Table.Column
        dataIndex="discipline_name"
        key="discipline_name"
        title="Disciplina"
        render={value => <TextField value={value} />}
      />
      <Table.Column
        dataIndex="content_name"
        key="content_name"
        title="Aula"
        render={value => <TextField value={value} />}
      />
      <Table.Column
        dataIndex="author_name"
        key="author_name"
        title="Aluno"
        render={value => <TextField value={value} />}
      />
      <Table.Column
        dataIndex="created_at"
        key="created_at"
        title="Data de postagem"
        render={value => (
          <TextField value={formatDate(value, 'DD/MM/YYYY HH:mm')} />
        )}
      />
      <Table.Column
        dataIndex="status"
        key="status"
        title="Status"
        render={value => (
          <TagField
            color={tagColorByStatus(value)}
            value={translateDiscussionStatus(value)}
          />
        )}
      />
      <Table.Column<IDiscussionThread>
        title="Ações"
        dataIndex="actions"
        align="center"
        width={80}
        render={(_, record) => {
          return (
            <Space>
              <ShowButton hideText size="small" recordItemId={record.id} />
              <DeleteButton size="small" recordItemId={record.id} hideText />
            </Space>
          );
        }}
      />
    </Table>
  );
};
