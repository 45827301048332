/* eslint-disable sort-imports-es6-autofix/sort-imports-es6 */
import './i18n';
import './styles/config.css';
import '@refinedev/antd/dist/reset.css';
import './styles/antd.less';
import App from './App';
import React from 'react';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekYear from 'dayjs/plugin/weekYear';
import weekday from 'dayjs/plugin/weekday';
import { CoreUploadProvider } from 'store/core-upload';
import { StyleProvider } from '@ant-design/cssinjs';
import { createRoot } from 'react-dom/client';

dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);
dayjs.extend(timezone);
dayjs.extend(utc);

const container = document.querySelector('#root');
const root = createRoot(container!);
root.render(
  <React.StrictMode>
    <StyleProvider hashPriority="low">
      <CoreUploadProvider>
        <App />
      </CoreUploadProvider>
    </StyleProvider>
  </React.StrictMode>
);
