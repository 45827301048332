import * as Icons from '@ant-design/icons';
import { Button, Tag, Typography } from 'antd';
import {
  IAudio,
  IDocument,
  ILibraryContent,
  IVideo
} from 'interfaces/libraries';
import { IHtmlDoc } from 'interfaces/prometheus';
import { LoadingOutlined } from '@ant-design/icons';
import { Show } from '@refinedev/antd';
import { ShowFieldData } from 'components/ShowFieldData';
import { SucellusJobStatusMap, getSucellusJob } from 'services/sucellus';
import { VideoSubtitleEditorModal } from 'components/modal/VideoSubtitleEditorModal';
import {
  getAudio,
  getDocument,
  getHtmlDoc,
  getVideo,
  translateKind
} from 'services/libraries/contents';
import { useEffect, useRef, useState } from 'react';
import { useModal, useShow } from '@refinedev/core';
import { useParams } from 'react-router-dom';

const { Title, Text } = Typography;

export const LibraryContentShow = () => {
  const { id, library_id } = useParams();
  const [video, setVideo] = useState<IVideo | null>(null);
  const [audio, setAudio] = useState<IAudio | null>(null);
  const [document, setDocument] = useState<IDocument | null>(null);
  const [htmlDoc, setHtmlDoc] = useState<IHtmlDoc | null>(null);
  const { queryResult } = useShow<ILibraryContent>({
    resource: `prometheus/v2/libraries/${library_id}/contents`,
    id
  });
  const [subtitleStatus, setSubtitleStatus] = useState<
    | 'PENDING'
    | 'TRANSCRIBING'
    | 'TRANSCRIBED'
    | 'REFINING'
    | 'COMPLETED'
    | 'FAILED'
  >('PENDING');

  const streamPlayerRef = useRef<HTMLIFrameElement>(null);

  const { data, isLoading } = queryResult;
  const record = data?.data;

  const videoSubtitleEditorModal = useModal();

  useEffect(() => {
    if (id && record?.kind === 'video') {
      getVideo(id).then(video => setVideo(video));
    }

    if (id && record?.kind === 'audio') {
      getAudio(id).then(audio => setAudio(audio));
    }

    if (id && record?.kind === 'document') {
      getDocument(id).then(document => setDocument(document));
    }

    if (id && record?.kind === 'html_doc') {
      getHtmlDoc(id).then(htmlDoc => setHtmlDoc(htmlDoc));
    }
  }, [id, record?.kind]);

  useEffect(() => {
    if (!video) {
      return;
    }

    getSucellusJob(video.delivery_option.reference).then(data => {
      setSubtitleStatus(data?.job?.transcription_job_status ?? 'PENDING');
    });
  }, [video]);

  return (
    <Show isLoading={isLoading} title="Visualizar conteúdo">
      <Title level={5}>ID</Title>
      <Text>{record?.id}</Text>
      <Title level={5}>Nome</Title>
      <Text>{record?.name}</Text>
      <Title level={5}>Tipo</Title>
      <Text>{translateKind(record?.kind)}</Text>
      {record?.kind === 'video' && (
        <>
          <Title level={5}>Video</Title>
          {video?.delivery_option &&
            video?.delivery_option.provider.kind === 'aws_s3' && (
              <video src={video?.delivery_option.delivery_url} controls />
            )}

          {video?.delivery_option &&
            video?.delivery_option.provider.kind === 'cloudflare_stream' && (
              <iframe
                ref={streamPlayerRef}
                src={video?.delivery_option.delivery_url}
                width="400"
                height="250"
              />
            )}

          {!video?.delivery_option && (
            <Text>
              <LoadingOutlined />
              Em processamento
            </Text>
          )}

          {video && (
            <div className="flex flex-col items-start gap-2">
              <ShowFieldData
                className="mt-2"
                label="Status de processamento da legenda"
                value={
                  <Tag
                    color={
                      subtitleStatus === 'COMPLETED' ? 'success' : 'orange'
                    }
                  >
                    {SucellusJobStatusMap[subtitleStatus]}
                  </Tag>
                }
              />
              {subtitleStatus === 'COMPLETED' && (
                <Button
                  type="default"
                  className="!flex items-center"
                  onClick={videoSubtitleEditorModal.show}
                >
                  <Icons.FileTextFilled />
                  Editar Legendas
                </Button>
              )}
            </div>
          )}
        </>
      )}

      {record?.kind === 'audio' && (
        <>
          <Title level={5}>Audio</Title>
          {audio?.delivery_option && (
            <audio src={audio?.delivery_option.delivery_url} controls />
          )}
          {!audio?.delivery_option && (
            <Text>
              <LoadingOutlined />
              Em processamento
            </Text>
          )}
        </>
      )}

      {record?.kind === 'document' && (
        <>
          <Title level={5}>Documento</Title>
          {document?.delivery_option && (
            <Button
              href={document?.delivery_option.delivery_url}
              target="_blank"
              rel="noopener noreferrer"
            >
              Baixar documento
            </Button>
          )}
          {!document?.delivery_option && (
            <Text>
              <LoadingOutlined />
              Em processamento
            </Text>
          )}
        </>
      )}

      {record?.kind === 'html_doc' && (
        <>
          <Title level={5}>HTML</Title>
          {htmlDoc?.delivery_option && (
            <Button
              href={htmlDoc?.delivery_option.delivery_url}
              target="_blank"
              rel="noopener noreferrer"
            >
              Abrir HTML
            </Button>
          )}
          {!htmlDoc?.delivery_option && (
            <Text>
              <LoadingOutlined />
              Em processamento
            </Text>
          )}
        </>
      )}

      {video && (
        <VideoSubtitleEditorModal
          modal={videoSubtitleEditorModal}
          videoId={video.delivery_option.reference}
        />
      )}
    </Show>
  );
};
