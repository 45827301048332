import React from 'react';
import { ForumListItem } from './ForumListItem';
import { IForum } from 'interfaces/forum';
import { Pagination } from 'antd';

export const ForumList: React.FC<{
  data: IForum[];
  total: number;
  pageSize: number;
  currentPage: number;
  onPageChange: (page: number, pageSize?: number) => void;
  refetch: () => void;
}> = ({ data, total, pageSize, currentPage, onPageChange, refetch }) => {
  return (
    <div>
      {data.map((forum: IForum, index) => (
        <ForumListItem key={index} forum={forum} refetch={refetch} />
      ))}
      <Pagination
        className="justify-self-end mt-4 text-center"
        hideOnSinglePage
        total={total}
        pageSize={pageSize}
        current={currentPage}
        onChange={onPageChange}
      />
    </div>
  );
};
