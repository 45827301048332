import {
  AuditOutlined,
  CommentOutlined,
  FileTextOutlined
} from '@ant-design/icons';
import { Col, Row } from 'antd';
import { CounterItem } from './CounterItem';

interface CountersProps {
  threadsCount: number;
  assessmentsCount: number;
  assignmentsCount: number;
}

export const Counters = ({
  assessmentsCount,
  assignmentsCount,
  threadsCount
}: CountersProps) => {
  return (
    <Row gutter={[24, 16]} className="mb-6">
      <Col xs={24} lg={8} className="md:flex">
        <CounterItem
          icon={CommentOutlined}
          title="Tira-dúvidas a responder"
          value={threadsCount || 0}
          link="/professor_area/discussion_threads"
        />
      </Col>

      <Col span={24} lg={8} className="md:flex">
        <CounterItem
          icon={FileTextOutlined}
          title="Atividades a corrigir"
          value={assessmentsCount || 0}
          link="?tab=evaluations"
        />
      </Col>

      <Col xs={24} lg={8} className="md:flex">
        <CounterItem
          icon={AuditOutlined}
          title="Avaliações a corrigir"
          value={assignmentsCount || 0}
          link="?tab=evaluations"
        />
      </Col>
    </Row>
  );
};
