import React from 'react';
import { Empty, EmptyProps, Flex } from 'antd';

export const EmptyState = (props: EmptyProps) => {
  return (
    <Flex
      className="h-[calc(100vh-160px)] w-full"
      align="center"
      justify="center"
    >
      <Empty {...props} />
    </Flex>
  );
};
