import React, { useEffect } from 'react';
import { Form, Input, Modal } from 'antd';
import { IProfessorAreaPostsForum } from 'interfaces/professor_area/forums';
import { useModal, useUpdate } from '@refinedev/core';

interface modalProps {
  modalProps: ReturnType<typeof useModal>;
  forum: IProfessorAreaPostsForum;
  refetch: () => void;
}

export const EditTopicTiteModal: React.FC<modalProps> = ({
  forum,
  modalProps,
  refetch
}) => {
  const [form] = Form.useForm();
  const { mutateAsync } = useUpdate();

  useEffect(() => {
    form.setFieldValue('title', forum.title);
  }, [forum, form]);

  const handleEdit = () => {
    form.validateFields().then(values => {
      mutateAsync({
        resource: 'professor_area/forums',
        id: forum.id,
        values: {
          title: values.title
        },
        successNotification: () => ({
          type: 'success',
          description: 'Sucesso!',
          message: 'Título editado com sucesso.'
        }),
        errorNotification: () => ({
          type: 'error',
          description: 'Ops!',
          message: 'Não foi possível editar o título. Tente novamente.'
        })
      })
        .then(() => {
          form.resetFields();
          refetch();
          modalProps.close();
        })
        .catch();
    });
  };

  return (
    <Modal
      title="Editar título do tópico"
      width={600}
      open={modalProps.visible}
      onCancel={modalProps.close}
      onOk={handleEdit}
      okText="Salvar"
      cancelText="Voltar"
      centered
    >
      <Form form={form}>
        <Form.Item name="title">
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};
