import { Button, Empty, Flex, Modal, Pagination, Typography } from 'antd';
import { ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { HttpError, useDelete } from '@refinedev/core';
import {
  IProfessorAreaNoticeBoardPost,
  IProfessorAreaNoticeBoardShow
} from 'interfaces/professor_area/notice_boards';
import { ModalCreateNoticeBoard } from './ModalCreate';
import { ModalShowNoticeBoard } from './ModalShow';
import { NoticeBoardItem } from './Item';
import { useCallback } from 'react';
import { useModalForm } from '@refinedev/antd';
import { useParams } from 'react-router-dom';

interface NoticeBoardItemProps {
  notices?: IProfessorAreaNoticeBoardShow[];
  page: number;
  total?: number;
  itemsPerPage?: number;
  onChangePage: (page: number) => void;
  refetch: () => void;
}

export const NoticeBoardList = ({
  notices,
  page,
  total,
  itemsPerPage,
  onChangePage,
  refetch
}: NoticeBoardItemProps) => {
  const params = useParams();
  const classroomId = params.id!;

  const { confirm } = Modal;

  const { mutateAsync: deleteNotice } =
    useDelete<IProfessorAreaNoticeBoardShow>();

  const {
    modalProps: createModalProps,
    formProps: createFormProps,
    show: createModalShow,
    close: createModalClose,
    formLoading
  } = useModalForm<
    IProfessorAreaNoticeBoardPost,
    HttpError,
    IProfessorAreaNoticeBoardPost
  >({
    resource: 'professor_area/notice_boards',
    action: 'create',
    redirect: false,
    autoResetForm: true,
    onMutationSuccess: () => {
      onChangePage(1);
    },
    successNotification: () => ({
      type: 'success',
      message: 'Aviso cadastrado com sucesso',
      description: 'Sucesso!'
    }),
    errorNotification: () => ({
      type: 'error',
      message: 'Não foi possível cadastradar o aviso. Tente novamente!',
      description: 'Algo deu errado!'
    })
  });

  const {
    modalProps: editModalProps,
    formProps: editFormProps,
    show: editModalShow,
    close: editModalClose
  } = useModalForm<
    IProfessorAreaNoticeBoardPost,
    HttpError,
    IProfessorAreaNoticeBoardPost
  >({
    resource: 'professor_area/notice_boards',
    action: 'edit'
  });

  const handleRemoveNotice = useCallback(
    (noticeId: string) => {
      confirm({
        title: 'Deseja excluir o aviso?',
        icon: <ExclamationCircleOutlined className="!text-red-500" />,
        content: 'Essa ação não pode ser revertida',
        okText: 'Excluir',
        cancelText: 'Voltar',
        okButtonProps: {
          danger: true
        },
        onOk() {
          deleteNotice({
            id: noticeId,
            resource: 'professor_area/notice_boards',
            successNotification: () => ({
              type: 'success',
              description: 'Sucesso!',
              message: 'Aviso excluído com sucesso.'
            }),
            errorNotification: () => ({
              type: 'error',
              description: 'Ops!',
              message: 'Não foi possível excluir o aviso. Tente novamente.'
            })
          }).then(() => {
            refetch();
            editModalClose();
          });
        }
      });
    },
    [confirm, deleteNotice, editModalClose, refetch]
  );

  return (
    <>
      <Flex justify="space-between" align="center" className="mb-4">
        <Typography.Title level={5}>Avisos da turma</Typography.Title>
        <Button
          icon={<PlusOutlined />}
          type="primary"
          onClick={() => createModalShow()}
        >
          Novo aviso
        </Button>
      </Flex>

      {notices && notices.length > 0 ? (
        <div>
          <Flex vertical>
            {notices.map(notice => (
              <NoticeBoardItem
                key={notice.id}
                notice={notice}
                onShow={noticeId => editModalShow(noticeId)}
                onRemove={handleRemoveNotice}
              />
            ))}
          </Flex>

          <Pagination
            className="justify-self-end mt-4"
            hideOnSinglePage
            current={page}
            total={total}
            pageSize={itemsPerPage}
            onChange={onChangePage}
          />
        </div>
      ) : (
        <Empty />
      )}

      {createModalProps.open && (
        <ModalCreateNoticeBoard
          modalProps={createModalProps}
          formProps={createFormProps}
          classroomId={classroomId}
          isLoading={formLoading}
          onCloseModal={createModalClose}
        />
      )}

      {editModalProps.open && !formLoading && (
        <ModalShowNoticeBoard
          modalProps={editModalProps}
          formProps={editFormProps}
          isLoading={formLoading}
          onCloseModal={editModalClose}
          onRemoveNotice={handleRemoveNotice}
        />
      )}
    </>
  );
};
