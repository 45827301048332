import { AddManagerModal } from 'components/modal/classroom/AddManagerModal';
import { Button, Divider, Modal, Space, Table, Tag, Typography } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { IClassroom } from 'interfaces/classrooms';
import { TextField, useTable } from '@refinedev/antd';
import { formatDate } from 'services/date';
import { useCallback, useMemo, useState } from 'react';
import { useCustomMutation, useModal } from '@refinedev/core';
import type { IAgent } from 'interfaces/agents';

export interface ClassroomManagersProps {
  classroom: IClassroom;
}

const ClassroomManagers = ({ classroom }: ClassroomManagersProps) => {
  const resource = 'agents';
  const addAgentModal = useModal();
  const deleteModal = useModal();

  const [agentIdForDelete, setAgenteIdForDelete] = useState('');

  const { tableProps, tableQueryResult } = useTable<IAgent>({
    resource,
    liveMode: 'manual',
    filters: {
      initial: [
        {
          field: 'filter[by_permission]',
          operator: 'eq',
          value: `professor_area:classrooms:${classroom.id}`
        }
      ]
    }
  });

  const totalAgents = useMemo(
    () => tableQueryResult.data?.total,
    [tableQueryResult.data?.total]
  );

  const selectedAgents = useMemo(
    () => tableQueryResult.data?.data || [],
    [tableQueryResult.data?.data]
  );

  const { mutateAsync, isLoading } = useCustomMutation();

  const onAddAgent = useCallback(
    async (agentId: string) => {
      await mutateAsync({
        url: `admin/agents/${agentId}/add_permissions`,
        method: 'post',
        values: {
          permissions: [`professor_area:classrooms:${classroom.id}`]
        },
        successNotification: () => {
          addAgentModal.close();
          tableQueryResult.refetch();
          return {
            type: 'success',
            description: 'Sucesso!',
            message: 'Docente adicionado com sucesso'
          };
        },
        errorNotification: () => {
          return {
            type: 'error',
            description: 'Ops! Algo de errado',
            message: 'Não é possível adicionar o docente. Tente novamente!'
          };
        }
      });
    },
    [mutateAsync, classroom.id, addAgentModal, tableQueryResult]
  );

  const onDeleteAgent = useCallback(async () => {
    await mutateAsync({
      url: `admin/agents/${agentIdForDelete}/remove_permissions`,
      method: 'post',
      values: {
        permissions: [`professor_area:classrooms:${classroom.id}`]
      },
      successNotification: () => {
        deleteModal.close();
        tableQueryResult.refetch();
        return {
          type: 'success',
          description: 'Sucesso!',
          message: 'Docente removido com sucesso'
        };
      },
      errorNotification: () => {
        return {
          type: 'error',
          description: 'Ops! Algo de errado',
          message: 'Não foi possível remover o docente. Tente novamente!'
        };
      }
    });
  }, [
    mutateAsync,
    agentIdForDelete,
    classroom.id,
    deleteModal,
    tableQueryResult
  ]);

  return (
    <>
      <Typography.Title level={5}>Corpo docente</Typography.Title>
      <Typography.Paragraph>
        Equipe responsável por gerenciar as atividades e oferecer suporte aos
        alunos. Esses usuários têm acesso à <strong>área do professor</strong>{' '}
        da turma e podem realizar as seguintes tarefas:
        <ul className="list-disc">
          <li>Visualizar informações da turma</li>
          <li>Criar e corrigir avaliações e atividades</li>
          <li>Gerenciar tópicos e respostas no fórum</li>
          <li>
            Responder perguntas enviadas pelos alunos na seção de tira-dúvidas
          </li>
          <li>Agendar ou cancelar aulas interativas</li>
          <li>Adicionar materiais complementares no conteúdo da turma</li>
        </ul>
      </Typography.Paragraph>

      <Divider />

      <Space
        align="center"
        className="w-full justify-between align-center mb-6"
      >
        <Space>
          <Typography.Title level={5} className="mb-0">
            Docentes
          </Typography.Title>
          <Tag>{totalAgents}</Tag>
        </Space>

        <Button type="primary" onClick={() => addAgentModal.show()}>
          Adicionar docente
        </Button>
      </Space>

      <Table {...tableProps} pagination={false} rowKey="id">
        <Table.Column
          dataIndex="name"
          key="name"
          title="Nome"
          render={value => <TextField value={value} />}
        />
        <Table.Column
          dataIndex="created_at"
          key="name"
          title="Data de inclusão"
          render={value => (
            <TextField value={formatDate(value, 'DD/MM/YYYY')} />
          )}
        />
        <Table.Column<{ id: string }>
          dataIndex="evaluation-actions"
          key="evaluation-actions"
          align="center"
          title="Ações"
          render={(_, record) => {
            return (
              <>
                <Button
                  size="small"
                  icon={<DeleteOutlined />}
                  onClick={() => {
                    setAgenteIdForDelete(record.id);
                    deleteModal.show();
                  }}
                />
              </>
            );
          }}
        />
      </Table>

      {addAgentModal.visible && (
        <AddManagerModal
          modal={addAgentModal}
          onSubmit={onAddAgent}
          selectedAgents={selectedAgents}
          loading={isLoading}
        />
      )}

      <Modal
        destroyOnClose
        open={deleteModal.visible}
        onCancel={deleteModal.close}
        confirmLoading={isLoading}
        onOk={onDeleteAgent}
        okType="primary"
        okText="Confirmar remoção"
        title="Remover docente da equipe da turma"
        width={400}
        okButtonProps={{
          danger: true
        }}
      >
        <Typography.Text>
          Ao confirmar, o docente perderá acesso à área do professor e não
          poderá mais realizar tarefas associadas a esta turma
        </Typography.Text>
      </Modal>
    </>
  );
};

export default ClassroomManagers;
