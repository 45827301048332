import clsx from 'clsx';
import {
  BellOutlined,
  CalendarOutlined,
  DeleteOutlined,
  EllipsisOutlined,
  EyeOutlined
} from '@ant-design/icons';
import { Button, Dropdown, Flex, Space, Tag, Typography } from 'antd';
import { IProfessorAreaNoticeBoardShow } from 'interfaces/professor_area/notice_boards';
import { MenuInfo } from 'rc-menu/lib/interface';
import { formatDate } from 'services/date';
import { useCallback, useState } from 'react';
import type { MenuProps } from 'antd/lib';

const items: MenuProps['items'] = [
  {
    key: 'show',
    label: 'Visualizar aviso',
    icon: <EyeOutlined />
  },
  {
    key: 'delete',
    label: 'Excluir aviso',
    icon: <DeleteOutlined />
  }
];

interface NoticeBoardItemProps {
  notice: IProfessorAreaNoticeBoardShow;
  onShow: (noticeId: string) => void;
  onRemove: (noticeId: string) => void;
}

export const NoticeBoardItem = ({
  notice,
  onShow,
  onRemove
}: NoticeBoardItemProps) => {
  const [hovered, setHover] = useState(false);

  const handleMenuClick = useCallback(
    (event: MenuInfo) => {
      if (event.key === 'show') {
        onShow(notice.id);
      } else {
        onRemove(notice.id);
      }
    },
    [notice.id, onRemove, onShow]
  );

  const menuProps: MenuProps = {
    items,
    onClick: handleMenuClick
  };

  return (
    <Flex
      justify="space-between"
      className={clsx(
        'group w-full items-center py-4 px-2 justify-between border-t border-t-neutral-100 hover:bg-neutral-100',
        hovered && 'bg-neutral-100'
      )}
    >
      <Space size="middle" className="flex-1">
        <Space
          direction="vertical"
          className="flex rounded-md w-[56px] h-[56px] p-2 bg-primary-500/10 justify-center items-center !gap-0"
        >
          <BellOutlined className="text-primary-500 text-2xl" />
        </Space>
        <Space direction="vertical">
          <Typography.Text strong className="text-sm font-semibold !m-0">
            {notice.title}
          </Typography.Text>
          <Space size="small">
            <CalendarOutlined className="text-neutral-500" />
            <Typography.Text className="!m-0 !text-neutral-500">
              {formatDate(notice.created_at, 'DD/MM/YYYY')}
            </Typography.Text>
          </Space>
        </Space>
      </Space>

      <Space>
        <Tag color={notice.priority === 'high' ? 'red' : 'default'}>
          Prioridade {notice.priority === 'high' ? 'alta' : 'padrão'}
        </Tag>
        <Dropdown
          menu={menuProps}
          trigger={['click']}
          onOpenChange={setHover}
          className={clsx(
            'opacity-0 group-hover:opacity-100 group-active:opacity-100',
            hovered && 'opacity-100'
          )}
        >
          <Button icon={<EllipsisOutlined />} />
        </Dropdown>
      </Space>
    </Flex>
  );
};
