import React from 'react';
import {
  Avatar,
  Button,
  Col,
  Divider,
  Modal,
  Progress,
  Row,
  Space,
  Typography
} from 'antd';
import { CommentOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { IStudentShow } from 'interfaces/professor_area/students';
import { TagField } from '@refinedev/antd';
import { formatDate } from 'services/date';
import { getInitialsName } from 'utils/get-initials-name';
import { useModal, useNavigation } from '@refinedev/core';
import { useParams } from 'react-router-dom';

const { Title, Text } = Typography;

interface StudentDetailsModalProps {
  modalProps: ReturnType<typeof useModal>;
  student?: IStudentShow;
  handleEditFrequency: (student: IStudentShow) => void;
}

export const StudentDetailsModal: React.FC<StudentDetailsModalProps> = ({
  student,
  modalProps,
  handleEditFrequency
}) => {
  const { id } = useParams();
  const { push, listUrl, showUrl } = useNavigation();

  if (!student) {
    return null;
  }

  const handleNavigateToForum = () => {
    const forumList = showUrl('professor_area/classrooms', id as string);

    push(`${forumList}?tab=forums`);
  };

  const handleNavigateToThreads = () => {
    const threadsList = listUrl('professor_area/discussion_threads');

    push(`${threadsList}?filter[by_user_name]=${student.student_name}`);
  };

  return (
    <Modal
      title={null}
      open={modalProps.visible}
      onCancel={modalProps.close}
      footer={null}
      centered
      width={600}
    >
      <Space direction="vertical" style={{ width: '100%' }}>
        {/* Header Section */}
        <Row align="middle" gutter={16}>
          <Col>
            <Avatar size={64} className="bg-[#a855f7]/10 text-[#9333ea]">
              {getInitialsName(student.student_name)}
            </Avatar>
          </Col>
          <Col flex="auto">
            <Title level={4} className="!m-0">
              {student.student_name}
            </Title>
            <Space>
              <Text type="secondary">
                Matrícula: {student.enrollment_registration}
              </Text>
              <Divider type="vertical" />
              <TagField color="success" value={student.status} />
            </Space>
          </Col>
        </Row>

        <Divider className="my-4" />

        {/* Course Information Section */}
        <Space direction="vertical" className="w-full gap-4">
          <Space className="w-full">
            <Text type="secondary" className="flex w-[134px]">
              Curso:
            </Text>
            <Text className="!text-[#344054]">{student.course_name}</Text>
          </Space>
          <Space>
            <Text type="secondary" className="flex w-[134px]">
              Data de inscrição:
            </Text>
            <Text className="!text-[#344054]">
              {formatDate(
                student.course_enrollment.created_at,
                'DD/MM/YYYY HH:mm'
              )}
            </Text>
          </Space>
          <Space>
            <Text type="secondary" className="flex w-[134px]">
              Data de expiração:
            </Text>
            <Text className="!text-[#344054]">
              {formatDate(
                student.course_enrollment.expires_at,
                'DD/MM/YYYY HH:mm'
              )}
            </Text>
          </Space>
        </Space>

        <Divider className="my-4" />

        <Title level={5}>Participação na turma</Title>
        <div className="grid md:grid-cols-3 gap-6">
          <Space
            align="center"
            direction="vertical"
            className="p-3 justify-end bg-neutral-100 rounded-lg"
          >
            <Progress
              type="circle"
              percent={student.attendance_rate}
              size={60}
            />
            <div className="text-center w-[125px]">
              Frequência
              <Divider className="mt-2 mb-0" />
            </div>

            <Button type="link" onClick={() => handleEditFrequency(student)}>
              Editar
            </Button>
          </Space>

          <Space
            align="center"
            direction="vertical"
            className="p-3 justify-end bg-neutral-100 rounded-lg"
          >
            <CommentOutlined className="text-[32px] text-[#00000026]" />
            <div className="text-center w-[125px]">
              <strong>{student?.forums_count || 0} interações</strong> no Fórum
              <Divider className="mt-2 mb-0" />
            </div>
            <Button type="link" onClick={handleNavigateToForum}>
              Ver todas
            </Button>
          </Space>

          <Space
            align="center"
            direction="vertical"
            className="p-3 justify-end bg-neutral-100 rounded-lg"
          >
            <QuestionCircleOutlined className="text-[32px] text-[#00000026]" />
            <div className="text-center w-[125px]">
              <strong>{student?.discussion_threads_count || 0} dúvidas</strong>{' '}
              enviadas aos professores
              <Divider className="mt-2 mb-0" />
            </div>
            <Button type="link" onClick={handleNavigateToThreads}>
              Ver dúvidas
            </Button>
          </Space>
        </div>
      </Space>
    </Modal>
  );
};
