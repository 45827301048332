import {
  Avatar,
  Button,
  Card,
  Flex,
  Form,
  Input,
  Modal,
  Space,
  Tag,
  Typography
} from 'antd';
import { EmptyState } from 'components/EmptyState';
import { IAgent } from 'interfaces/agents';
import { ITeacherDiscussionThread } from 'interfaces/teacher_discussion_threads';
import { LeftOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Show, TagField } from '@refinedev/antd';
import { createPost } from 'requests/posts';
import { formatDate } from 'services/date';
import { getInitialsName } from 'utils/get-initials-name';
import { tagColorByStatus } from 'utils/statusColorMapping';
import { translateDiscussionStatus } from '.';
import { useCallback } from 'react';
import { useGetIdentity, useShow } from '@refinedev/core';

const { Text } = Typography;

export const DiscussionThreadShow = () => {
  const { queryResult, showId } = useShow<ITeacherDiscussionThread>();
  const [form] = Form.useForm();

  const { data, isLoading, refetch } = queryResult;
  const record = data?.data;

  const user = useGetIdentity<IAgent>();
  const [modal, contextHolder] = Modal.useModal();

  const handleSubmit = useCallback(async () => {
    try {
      const { thread } = await form.getFieldsValue();

      await createPost({ threadId: showId as string, text: thread });
    } finally {
      form.resetFields();
      refetch();
    }
  }, [form, showId, refetch]);

  const onAnswer = useCallback(async () => {
    try {
      await form.validateFields();

      modal.confirm({
        title: 'Tem certeza que deseja enviar a resposta?',
        content: 'A operação não pode ser desfeita ',
        okText: 'Envir resposta',
        cancelText: 'Cancelar',

        onOk: handleSubmit
      });
    } catch {}
  }, [modal, form, handleSubmit]);

  return (
    <Show
      breadcrumb={false}
      isLoading={isLoading}
      headerProps={{
        title: false
      }}
      goBack={<GoBack />}
      headerButtonProps={{
        hidden: true
      }}
      contentProps={{
        className: '!bg-transparent !shadow-none',
        styles: {
          cover: {
            background: 'red'
          },
          body: {
            padding: 0,
            backgroundColor: 'transparent',
            height: '100%'
          }
        }
      }}
    >
      {!isLoading && !record ? (
        <EmptyState description="Nenhuma dúvida encontrada!" />
      ) : (
        <Flex vertical gap={16}>
          <Card>
            <Space size="middle">
              <div className="bg-primary-50 p-4 rounded-lg flex items-center justify-center">
                <QuestionCircleOutlined className="text-2xl text-primary-500" />
              </div>

              <div>
                <Typography.Title level={3} className="">
                  Dúvida do aluno
                </Typography.Title>
                <Flex>
                  {record?.content_name && <Tag>{record?.content_name}</Tag>}
                  <Tag>{record?.discipline_name}</Tag>
                  <TagField
                    color={tagColorByStatus(record?.status || 'unknown')}
                    value={translateDiscussionStatus(record?.status)}
                  />
                </Flex>
              </div>
            </Space>
          </Card>

          <Card title="Registro da conversa">
            <Flex vertical gap={24} className="mb-6">
              {record?.posts.map(post => (
                <Flex vertical key={post.id} gap={4}>
                  <Flex gap={16} align="center">
                    <Avatar className="bg-primary-50 text-primary-500">
                      {getInitialsName(post.author.name)}
                    </Avatar>
                    <Space>
                      <Text strong>{post.author.name}</Text>
                      <Text>•</Text>
                      <Text type="secondary">
                        {formatDate(post.created_at, 'DD/MM/YYYY HH:mm')}
                      </Text>
                    </Space>
                  </Flex>
                  <div className="bg-neutral-100 p-4 rounded-lg ml-12">
                    <Text>{post.text}</Text>
                  </div>
                </Flex>
              ))}
            </Flex>

            {record?.status === 'pending' && (
              <Flex vertical gap={4}>
                <Flex gap={16} align="center">
                  <Avatar className="bg-primary-50 text-primary-500">
                    {getInitialsName(user.data?.name || '')}
                  </Avatar>
                  <Text strong>{user.data?.name}</Text>
                </Flex>
                <div className="bg-neutral-100 p-4 rounded-lg ml-12">
                  <Form form={form} preserve={false}>
                    <Form.Item
                      name="thread"
                      rules={[{ required: true, message: 'Campo obrigatório' }]}
                    >
                      <Input.TextArea
                        size="large"
                        placeholder="Escreva sua resposta"
                      />
                    </Form.Item>

                    <Space className="w-full justify-end">
                      <Button key="submit" type="primary" onClick={onAnswer}>
                        Responder
                      </Button>
                    </Space>
                  </Form>
                </div>
              </Flex>
            )}
          </Card>
        </Flex>
      )}
      {contextHolder}
    </Show>
  );
};

const GoBack = () => (
  <Space className="items-center !text-neutral-500">
    <LeftOutlined />
    <Text className="!text-neutral-500 font-normal">
      Voltar para lista de dúvidas
    </Text>
  </Space>
);
