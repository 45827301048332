import React, { useState } from 'react';
import clsx from 'clsx';
import {
  Avatar,
  Button,
  Divider,
  Dropdown,
  MenuProps,
  Progress,
  Space,
  Typography
} from 'antd';
import { EditOutlined, EllipsisOutlined, EyeOutlined } from '@ant-design/icons';
import { IStudent, IStudentShow } from 'interfaces/professor_area/students';
import { getAPI } from 'requests/api';
import { getInitialsName } from 'utils/get-initials-name';
import { useParams } from 'react-router-dom';

const { Text } = Typography;

export const StudentListItem: React.FC<{
  student: IStudent;
  handleEditFrequency: (student: IStudentShow) => void;
  handleShowDetails: (student: IStudentShow) => void;
}> = ({ student, handleEditFrequency, handleShowDetails }) => {
  const [hovered, setHover] = useState(false);
  const { id } = useParams();

  const getStudentData = async (
    callback: typeof handleEditFrequency | typeof handleShowDetails
  ) => {
    const studentData = await getAPI()
      .get(`/admin/professor_area/classrooms/${id}/enrollments/${student.id}`)
      .catch(() => {
        throw new Error('Não foi possivel encontrar os dados do aluno');
      });
    callback(studentData?.data);
  };
  const menu = [
    {
      key: '1',
      label: (
        <Button
          className="!text-[#000000E0]"
          type="link"
          icon={<EditOutlined />}
          onClick={() => getStudentData(handleEditFrequency)}
        >
          Editar Frequência
        </Button>
      )
    },
    {
      key: '2',
      label: (
        <Button
          className="!text-[#000000E0]"
          type="link"
          icon={<EyeOutlined />}
          onClick={() => getStudentData(handleShowDetails)}
        >
          Ver dados do aluno
        </Button>
      )
    }
  ];
  return (
    <div className="group flex w-full py-4 px-2 justify-between hover:bg-neutral-100 border-b-[1px] border-solid border-color-bg-neutral-100 first:border-t-[1px] first:border-solid first:border-color-bg-neutral-100">
      <Space>
        <Avatar size={40} className="bg-[#a855f7]/10 text-[#9333ea]">
          {getInitialsName(student.student_name)}
        </Avatar>
        <div>
          <Text strong>{student.student_name}</Text>
          <div>
            <Text type="secondary">
              Matrícula: {student.enrollment_registration}
            </Text>
            <Divider type="vertical" />
            <Text type="secondary">Curso: {student.course_name}</Text>
          </div>
        </div>
      </Space>
      <Space align="center">
        <Space>
          <Progress
            type="circle"
            percent={student.attendance_rate}
            size={24}
            showInfo={false}
          />
          <Text type="secondary" className="ml-2">
            {student.attendance_rate}% de frequência
          </Text>
        </Space>
        <Dropdown
          menu={{ items: menu } as MenuProps}
          placement="bottomLeft"
          onOpenChange={setHover}
          className={clsx(
            'opacity-0 group-hover:opacity-100 group-active:opacity-100',
            hovered && 'opacity-100'
          )}
        >
          <Button icon={<EllipsisOutlined />} />
        </Dropdown>
      </Space>
    </div>
  );
};
