export const SucellusJobStatusMap: Record<string, string> = {
  PENDING: 'Pendente',
  TRANSCRIBING: 'Processando',
  TRANSCRIBED: 'Concluído',
  REFINING: 'Refinando',
  COMPLETED: 'Concluído',
  FAILED: 'Falhou'
};

export const SUCELLUS_API_KEY = '9b24ef76-bf59-4a8d-bb2d-f989f6669780';

export const getSucellusJob = async (jobId: string) => {
  const response = await fetch(
    `https://sucellus.qconcursos.workers.dev/api/jobs/${jobId}`,
    {
      headers: {
        'Content-Type': 'application/json',
        'X-API-KEY': SUCELLUS_API_KEY
      }
    }
  )
    .then(response => response.json())
    .catch(() => {
      return null;
    });

  return await response;
};

export const getSucellusVideoSubtitles = async (videoId: string) => {
  const response = await fetch(
    `https://sucellus.qconcursos.workers.dev/api/subtitles/${videoId}`,
    {
      headers: {
        'Content-Type': 'application/json',
        'X-API-KEY': SUCELLUS_API_KEY
      }
    }
  );

  return await response.json();
};

export const updateSucellusVideoSubtitles = async (
  videoId: string,
  subtitles: string
) => {
  const response = await fetch(
    `https://sucellus.qconcursos.workers.dev/api/subtitles/${videoId}`,
    {
      method: 'PUT',
      body: JSON.stringify({ subtitles }),
      headers: {
        'Content-Type': 'application/json',
        'X-API-KEY': SUCELLUS_API_KEY
      }
    }
  );

  return await response.json();
};
