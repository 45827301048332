import { Button, Col, Form, Input, Row, Select } from 'antd';
import { FilterOutlined, SearchOutlined } from '@ant-design/icons';

export interface FilterNoticeBoard {
  by_title: string;
  by_priority?: 'regular' | 'hight';
}
interface NoticeBoardFilterProps {
  isLoading: boolean;
  onClear: () => void;
  onSearch: (formData: FilterNoticeBoard) => void;
}

export const NoticeBoardFilter = ({
  isLoading,
  onClear,
  onSearch
}: NoticeBoardFilterProps) => {
  const [form] = Form.useForm();

  const handleClear = () => {
    form.resetFields();
    onClear();
  };

  return (
    <Form form={form} layout="vertical" onFinish={onSearch}>
      <Row gutter={[16, 16]}>
        <Col xs={24} md={8}>
          <Form.Item name="by_title">
            <Input
              size="large"
              placeholder="Buscar pelo título do aviso..."
              suffix={<SearchOutlined />}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={8}>
          <Form.Item name="by_priority">
            <Select size="large" placeholder="Todos as prioridades" allowClear>
              <Select.Option key="regular">Padrão</Select.Option>
              <Select.Option key="high">Alta</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} md={8}>
          <Button
            type="default"
            className="mr-2"
            htmlType="submit"
            size="large"
            loading={isLoading}
            icon={<FilterOutlined />}
          >
            Filtrar
          </Button>

          <Button type="text" size="large" onClick={handleClear}>
            Limpar filtro
          </Button>
        </Col>
      </Row>
    </Form>
  );
};
