import { Button, Col, Form, FormProps, Input, Row, Select } from 'antd';
import { FilterOutlined, SearchOutlined } from '@ant-design/icons';
import { IGraduateDiscipline } from 'interfaces/graduate_disciplines';
import { useSelect } from '@refinedev/antd';

export interface IDiscussionThreadsFilter {
  by_post_text: string;
  by_user_name: string;
  by_grandparent_id: string;
  by_status: string;
  parent_reference: string;
}

interface FilterFormProps {
  searchFormProps: FormProps<IDiscussionThreadsFilter>;
}

const DISCUSSION_THREAD_STATUSES: Record<string, string> = {
  closed: 'Encerrada',
  answered: 'Respondida',
  pending: 'Pendente',
  opened: 'Aberta',
  archived: 'Arquivada',
  redacted: 'Redigida',
  censored: 'Censurada'
};

export const DiscussionThreadsFilter = ({
  searchFormProps
}: FilterFormProps) => {
  // TODO - Implementar filtro de disciplina
  const { selectProps: disciplineSelectProps } = useSelect<IGraduateDiscipline>(
    {
      resource: 'graduate_disciplines',
      optionLabel: 'name',
      optionValue: 'id',

      onSearch: value => [
        {
          field: 'filter[name]',
          operator: 'eq',
          value
        }
      ],

      pagination: {
        mode: 'server'
      }
    }
  );

  const handleClear = () => {
    searchFormProps.form?.resetFields();

    if (Object.keys(searchFormProps?.initialValues || {}).length > 0) {
      searchFormProps.form?.setFieldsValue({
        by_user_name: undefined
      });
    }

    searchFormProps.form?.submit();
  };

  return (
    <Form {...searchFormProps} layout="vertical">
      <Row gutter={[16, 16]}>
        <Col xs={24} md={8}>
          <Form.Item name="by_post_text">
            <Input
              size="large"
              placeholder="Buscar dúvida"
              suffix={<SearchOutlined />}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={8}>
          <Form.Item name="by_grandparent_id">
            <Select
              size="large"
              {...disciplineSelectProps}
              placeholder="Disciplina"
              defaultValue={null}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={8}>
          <Form.Item name="by_user_name">
            <Input placeholder="Aluno" size="large" />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col xs={24} md={8}>
          <Form.Item name="by_status">
            <Select
              size="large"
              placeholder="Todos os status"
              defaultValue={null}
            >
              <Select.Option>Todos</Select.Option>
              {Object.entries(DISCUSSION_THREAD_STATUSES).map(item => (
                <Select.Option key={item[0]}>{item[1]}</Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} md={8}>
          <Button
            type="default"
            className="mr-2"
            htmlType="submit"
            size="large"
            icon={<FilterOutlined />}
          >
            Filtrar
          </Button>

          <Button type="text" size="large" onClick={handleClear}>
            Limpar filtro
          </Button>
        </Col>
      </Row>
    </Form>
  );
};
