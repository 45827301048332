import {
  Button,
  Card,
  Dropdown,
  MenuProps,
  Modal,
  Space,
  Typography
} from 'antd';
import {
  CommentOutlined,
  DeleteOutlined,
  EditOutlined,
  EllipsisOutlined,
  ExclamationCircleOutlined,
  LeftOutlined
} from '@ant-design/icons';
import { EditTopicTiteModal } from 'components/modal/professor_area/forums/EditTopicTiteModal';
import { IProfessorAreaPosts } from 'interfaces/professor_area/forums';
import { MenuInfo } from 'rc-menu/lib/interface';
import { PostItem } from './PostItem';
import { Show, TagField, useTable } from '@refinedev/antd';
import { Stack } from '@qcx/ui';
import { TopicList } from './TopicList';
import { getForumTagData } from 'services/professor_area/forum';
import { useCallback } from 'react';
import { useDelete, useList, useModal } from '@refinedev/core';
import { useParams } from 'react-router-dom';

const { Title, Text } = Typography;
const { confirm } = Modal;

const items: MenuProps['items'] = [
  {
    key: 'edit',
    label: 'Editar título do tópico',
    icon: <EditOutlined />
  },
  {
    key: 'close',
    label: 'Fechar tópico',
    icon: <DeleteOutlined />
  }
];

export const TopicShow = () => {
  const { id } = useParams();
  const editModalProps = useModal();
  const { mutateAsync } = useDelete();
  const {
    tableQueryResult: { data, isLoading, refetch },
    current,
    setCurrent,
    pageSize
  } = useTable({
    resource: `professor_area/forums/${id}/posts`,
    initialPageSize: 5
  });
  const { data: queryPostData, refetch: refetchFirstPost } =
    useList<IProfessorAreaPosts>({
      resource: `professor_area/forums/${id}/posts`,
      pagination: {
        pageSize: 1,
        mode: 'server'
      }
    });

  const firstPostData = queryPostData?.data;
  const posts: IProfessorAreaPosts[] = (
    current === 1 ? data?.data?.slice(1) : data?.data
  ) as IProfessorAreaPosts[];

  const tagData = getForumTagData(firstPostData?.[0]?.forum);

  const handleCloseForum = useCallback(() => {
    if (firstPostData) {
      confirm({
        title: 'Deseja fechar esse tópico?',
        content: 'Essa ação não pode ser revertida',
        icon: <ExclamationCircleOutlined style={{ color: 'red' }} />,
        okButtonProps: { type: 'primary', danger: true },
        okText: 'Fechar tópico',
        cancelText: 'Voltar',
        onOk: () => {
          mutateAsync({
            resource: 'professor_area/forums',
            id: firstPostData?.[0].forum.id,
            successNotification: () => ({
              type: 'success',
              description: 'Sucesso!',
              message: 'Forum fechado com sucesso'
            }),
            errorNotification: () => ({
              type: 'error',
              description: 'Ops!',
              message: 'Não foi possível fechar este forum. Tente novamente.'
            })
          })
            .then(() => {
              refetch();
            })
            .catch();
        }
      });
    }
  }, [firstPostData, mutateAsync, refetch]);

  const handleDropdown = useCallback(
    (event: MenuInfo) => {
      if (event.key === 'edit') {
        editModalProps.show();
      } else {
        handleCloseForum();
      }
    },
    [editModalProps, handleCloseForum]
  );

  if (!firstPostData) {
    return null;
  }

  return (
    <Show
      breadcrumb={false}
      isLoading={isLoading}
      headerProps={{
        title: false
      }}
      goBack={<GoBack />}
      headerButtonProps={{
        hidden: true
      }}
      contentProps={{
        className: '!bg-transparent !shadow-none',
        styles: {
          body: {
            padding: 0,
            backgroundColor: 'transparent'
          }
        }
      }}
    >
      {firstPostData && (
        <>
          <Card className="!mb-4">
            <Stack className="items-center justify-between">
              <Stack className="items-center gap-4">
                <Space
                  direction="vertical"
                  className="flex rounded-md w-[56px] h-[56px] p-2 bg-[#a855f7]/10 justify-center items-center !gap-0"
                >
                  <CommentOutlined className="text-[#9333ea] text-2xl" />
                </Space>
                <Stack flow="column">
                  <Title level={3}>{firstPostData?.[0]?.forum?.title}</Title>
                  <Stack flow="row" className="!gap-1">
                    <Typography.Text type="secondary">
                      Tópico da turma
                    </Typography.Text>
                    <TagField
                      color="default"
                      value={firstPostData?.[0]?.forum?.grandparent.code}
                    />
                    <TagField
                      color="default"
                      value={`${firstPostData?.[0]?.forum?.grandparent?.discipline?.name}`}
                    />
                    <TagField color={tagData.color} value={tagData.label} />
                  </Stack>
                </Stack>
              </Stack>
              <Dropdown
                menu={{ items, onClick: handleDropdown } as MenuProps}
                placement="bottomLeft"
              >
                <Button icon={<EllipsisOutlined />} />
              </Dropdown>
            </Stack>
          </Card>

          <Card>
            <Typography.Title level={4}>Mensagem inicial</Typography.Title>
            <PostItem postData={firstPostData?.[0]} refetch={refetch} />
            <Stack className="mt-6">
              {posts && (
                <TopicList
                  data={posts}
                  total={data!.total}
                  pageSize={pageSize}
                  currentPage={current}
                  onPageChange={setCurrent}
                  refetch={refetch}
                />
              )}
            </Stack>
            <PostItem
              postData={firstPostData?.[0]}
              refetch={refetch}
              inputMode
            />
          </Card>
        </>
      )}
      <EditTopicTiteModal
        modalProps={editModalProps}
        forum={firstPostData?.[0]?.forum}
        refetch={refetchFirstPost}
      />
    </Show>
  );
};

const GoBack = () => (
  <Space className="items-center !text-neutral-500">
    <LeftOutlined />
    <Text className="!text-neutral-500 font-normal">Visualizar tópicos</Text>
  </Space>
);
