import {
  DiscussionThreadList,
  DiscussionThreadShow
} from 'pages/teacher_space/discussion_threads';
import { IResource } from 'interfaces/resources';
import { generateResources } from 'utils/GenerateResouces';

const resource: IResource = {
  name: 'professor_area/discussion_threads',
  label: 'Tira-dúvidas',
  list: DiscussionThreadList,
  show: DiscussionThreadShow,
  actions: ['list', 'show', 'delete'],
  parentName: 'professor_area',
  route: 'professor_area/discussion_threads'
};

export default generateResources(resource);
