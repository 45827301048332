import {
  Alert,
  Modal,
  Space,
  Upload,
  UploadFile,
  UploadProps,
  message
} from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { useCreate, useModal } from '@refinedev/core';
import { useState } from 'react';

const { Dragger } = Upload;

export interface ImportAttendanceModalProps {
  modalProps: ReturnType<typeof useModal>;
  refetch: () => void;
  classroomId: string;
}

export const ImportAttendanceModal = ({
  modalProps,
  classroomId,
  refetch
}: ImportAttendanceModalProps) => {
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [uploading, setUploading] = useState(false);
  const { mutateAsync } = useCreate();

  const handleUpload = () => {
    const formData = new FormData();

    for (const file of fileList) {
      formData.append('csv_file', file as unknown as Blob);
    }
    setUploading(true);
    mutateAsync(
      {
        resource: `professor_area/classrooms/${classroomId}/enrollments/import_frequency`,
        values: formData,
        successNotification: () => ({
          type: 'success',
          description: 'Sucesso!',
          message: 'Upload de arquivo realizado com sucesso'
        }),
        errorNotification: () => ({
          type: 'error',
          description: 'Ops!',
          message:
            'Não foi possível realizar o upload do arquivo. Tente novamente.'
        })
      },
      {
        onSuccess: () => {
          setFileList([]);
          refetch();
          modalProps.close();
        }
      }
    )
      .finally(() => {
        setUploading(false);
      })
      .catch(() => null);
  };

  const props: UploadProps = {
    name: 'csv_file',
    multiple: false,
    fileList,
    beforeUpload: file => {
      const isCSV = file.type === 'text/csv';

      if (!isCSV) {
        message.error(`${file.name} não é um arquivo CSV.`);
        return false;
      }

      setFileList([file]);
      return false;
    },
    onRemove: file => {
      const index = fileList.indexOf(file);
      const newFileList = [...fileList];
      newFileList.splice(index, 1);
      setFileList(newFileList);
    }
  };
  return (
    <Modal
      style={{ maxWidth: 412 }}
      {...modalProps}
      title="Importar frequência"
      onCancel={modalProps.close}
      cancelText="Cancelar"
      okText="Importar"
      onOk={handleUpload}
      className="!w-[1022px]"
      destroyOnClose
      confirmLoading={uploading}
    >
      <Space direction="vertical">
        <Alert
          type="info"
          showIcon
          message={
            <>
              <p>
                As frequências que não forem atualizadas, deverão ser editadas
                manualmente.
              </p>
              <p>
                O arquivo .csv deve conter uma coluna de <b>E-mail</b> (texto) e
                outra com a <b>Frequência</b> (número).
              </p>
              <ul>
                <b>
                  <li>Formatos CSV:</li>
                  <li>"E-mail;Frequência"</li>
                  <li>"E-mail,Frequência"</li>
                </b>
              </ul>
            </>
          }
        />
        <Dragger {...props}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            Clique aqui ou arraste o arquivo para upload.
          </p>
        </Dragger>
      </Space>
    </Modal>
  );
};
