import React from 'react';
import { IStudent, IStudentShow } from 'interfaces/professor_area/students';
import { Pagination } from 'antd';
import { StudentListItem } from './StudentListItem';

export const StudentList: React.FC<{
  data: IStudent[];
  total: number;
  pageSize: number;
  currentPage: number;
  handleEditFrequency: (student: IStudentShow) => void;
  handleShowDetails: (student: IStudentShow) => void;
  onPageChange: (page: number, pageSize?: number) => void;
}> = ({
  data,
  total,
  pageSize,
  currentPage,
  onPageChange,
  handleEditFrequency,
  handleShowDetails
}) => {
  return (
    <div>
      {data.map((student: IStudent, index) => (
        <StudentListItem
          key={index}
          student={student}
          handleEditFrequency={handleEditFrequency}
          handleShowDetails={handleShowDetails}
        />
      ))}
      <Pagination
        className="justify-self-end mt-4 text-center"
        hideOnSinglePage
        total={total}
        pageSize={pageSize}
        current={currentPage}
        onChange={onPageChange}
      />
    </div>
  );
};
