import { IResource } from 'interfaces/resources';
import { generateResources } from 'utils/GenerateResouces';

const resource: IResource = {
  name: 'professor_area/classroom_evaluations',
  label: 'Avaliações',
  actions: ['show', 'create', 'edit', 'delete'],
  parentName: 'professor_area',
  onlyPermission: true
};

export default generateResources(resource);
