import {
  Button,
  DatePicker,
  Form,
  FormProps,
  Input,
  Modal,
  ModalProps,
  Select,
  Space,
  Typography
} from 'antd';
import {
  DownloadOutlined,
  EditOutlined,
  ExclamationCircleFilled,
  PlayCircleOutlined
} from '@ant-design/icons';
import { FormItem } from './FormItem';
import { IDamVideo } from 'interfaces/dam_videos';
import { IInteractiveClass } from 'interfaces/interactive_classes';
import { IProfessor } from 'interfaces/professor';
import { TagField, useSelect } from '@refinedev/antd';
import {
  downloadReportFile,
  getStatusColor,
  getTimeOptions,
  translateStatus
} from 'services/professor_area/interactive-classes';
import { getAPI } from 'requests/api';
import { toSaoPauloTimeZone } from 'services/date';
import { useCallback, useEffect, useState } from 'react';
import { useDelete, useNotification, useUpdate } from '@refinedev/core';

const { confirm } = Modal;

interface ShowClassModalProps {
  modalProps: ModalProps;
  formProps: FormProps;
  closeModal: () => void;
  isEdit: boolean;
  previewModal?: (videoData: IDamVideo) => () => void;
  refetch: () => void;
}

export const ShowClassModal = ({
  modalProps,
  formProps,
  isEdit,
  closeModal,
  previewModal,
  refetch
}: ShowClassModalProps) => {
  const notification = useNotification();
  const [modalContext, setModalContext] = useState<'show' | 'edit'>('show');
  const { mutateAsync: editMutation } = useUpdate();
  const { mutateAsync: deleteInteractiveClass } =
    useDelete<IInteractiveClass>();
  const { selectProps: professorSelectProps } = useSelect<IProfessor>({
    resource: 'professors',
    optionLabel: (item: IProfessor) => `${item.name} - (${item.email})`,
    optionValue: 'id',
    onSearch: value => [
      {
        field: 'filter[by_name]',
        operator: 'eq',
        value
      }
    ]
  });

  const handlePreviewData = useCallback(async () => {
    const videoData = await getAPI()
      .get(`admin/prometheus/videos/${formProps?.initialValues?.video_id}`)
      .catch(() => {
        notification.open?.({
          type: 'error',
          message: 'Error!',
          description: 'Não foi possivel carregar dados do video.',
          key: `${formProps?.initialValues?.video_id}-error-get`
        });
      });

    previewModal?.(videoData?.data);
  }, [previewModal, formProps?.initialValues, notification]);

  const confirmCancel = () => {
    confirm({
      title: 'Cancelar aula interativa?',
      icon: <ExclamationCircleFilled className="!text-[red]" />,
      content: 'Tem certeza que deseja cancelar a aula ?',
      okText: 'Cancelar',
      cancelText: 'Voltar',
      okButtonProps: { type: 'primary', danger: true },
      onOk() {
        deleteInteractiveClass({
          id: formProps?.initialValues?.id,
          resource: 'interactive_classes',
          successNotification: () => ({
            type: 'success',
            description: 'Sucesso!',
            message: 'Aula interativa cancelada com sucesso.'
          }),
          errorNotification: () => ({
            type: 'error',
            description: 'Ops!',
            message:
              'Não foi possível cancelar a aula interativa. Tente novamente.'
          })
        }).then(() => {
          refetch();
          closeModal();
        });
      }
    });
  };

  useEffect(() => {
    setModalContext(isEdit ? 'edit' : 'show');
  }, [isEdit]);

  const getInitialValues = () => {
    const initialValues = { ...formProps.initialValues };

    initialValues.start_time = initialValues?.start_time
      ? toSaoPauloTimeZone(initialValues?.start_time)
      : undefined;
    initialValues.professor_ids = initialValues?.professors?.map(
      (professor: IProfessor) => ({
        id: professor.id,
        label: professor.name
      })
    );

    return initialValues;
  };

  const handleLinkAction = useCallback(() => {
    window.open(formProps?.initialValues?.join_url);
  }, [formProps?.initialValues?.join_url]);

  const handeUpdateAction = useCallback(async () => {
    await formProps.form
      ?.validateFields()
      .then(res => {
        editMutation({
          resource: 'professor_area/interactive_classes',
          id: formProps?.initialValues?.id,
          values: { title: res.title, description: res.description }
        }).finally(() => {
          closeModal();
        });
      })
      .catch(() => {
        notification.open?.({
          type: 'error',
          message: 'Favor conferir todos os campos obrigatórios.',
          description: 'Error ao atualizar registro!'
        });
      });
  }, [formProps, notification, editMutation, closeModal]);

  return (
    <Modal
      {...modalProps}
      afterClose={() => {
        formProps.form?.resetFields();
        setModalContext('show');
      }}
      title="Visualizar aula interativa"
      footer={
        <Space className="w-full flex justify-between">
          <Space>
            {modalContext !== 'show' &&
              formProps?.initialValues?.status !== 'finished' && (
                <Button type="text" danger onClick={confirmCancel}>
                  Cancelar aula
                </Button>
              )}
          </Space>
          <Space className="justify-self-end">
            <Button onClick={closeModal}>Voltar</Button>
            <Button
              type={modalContext === 'show' ? 'default' : 'primary'}
              icon={modalContext === 'show' && <EditOutlined />}
              className={`${
                formProps?.initialValues?.status === 'finished' && 'hidden'
              }`}
              onClick={() =>
                setModalContext(prev => {
                  if (prev === 'show') {
                    return 'edit';
                  }
                  void handeUpdateAction();
                  return prev;
                })
              }
            >
              {modalContext === 'show' ? 'Editar' : 'Salvar'}
            </Button>
          </Space>
        </Space>
      }
    >
      <Form {...formProps} initialValues={getInitialValues()} disabled>
        {modalContext === 'show' && (
          <Space className="w-full mb-4">
            <Typography.Text className="block !w-[140px] p-2 !text-[#4b5563] font-medium">
              Status
            </Typography.Text>
            <TagField
              color={getStatusColor(formProps?.initialValues?.status)}
              value={translateStatus(formProps?.initialValues?.status)}
            />
          </Space>
        )}
        <FormItem
          name="title"
          label="Título da aula"
          rules={[{ required: true, message: 'O título é obrigatório' }]}
        >
          <Input disabled={modalContext === 'show'} />
        </FormItem>

        {formProps?.initialValues?.join_url &&
          formProps?.initialValues?.status !== 'finished' && (
            <FormItem
              name="join_url"
              label="Link da aula"
              labelCol={{ className: 'w-[150px] p-2' }}
            >
              <Button
                disabled={false}
                type="primary"
                className="mt-2"
                icon={<PlayCircleOutlined />}
                onClick={handleLinkAction}
              >
                Entrar na sala
              </Button>
            </FormItem>
          )}

        {formProps?.initialValues?.status === 'finished' && (
          <Space className="w-full mb-4">
            <Typography.Text className="block !w-[140px] p-2 !text-[#4b5563] font-medium">
              Relatório de presença
            </Typography.Text>
            <Button
              disabled={false}
              type="primary"
              className="mt-2"
              icon={<DownloadOutlined />}
              onClick={() =>
                downloadReportFile(formProps?.initialValues?.id, notification)
              }
            >
              Baixar relatório de presença
            </Button>
          </Space>
        )}

        {formProps?.initialValues?.status === 'finished' && (
          <Space className="w-full mb-4">
            <Typography.Text className="block !w-[140px] p-2 !text-[#4b5563] font-medium">
              Aula gravada
            </Typography.Text>
            <Button
              disabled={false}
              type="primary"
              className="mt-2"
              icon={<DownloadOutlined />}
              onClick={handlePreviewData}
            >
              Visualizar aula gravada
            </Button>
          </Space>
        )}

        <FormItem
          name="description"
          label="Descrição"
          labelCol={{ className: 'w-[150px] p-2' }}
        >
          <Input.TextArea
            placeholder="Adicionar descrição"
            disabled={modalContext === 'show'}
          />
        </FormItem>

        <Space className="flex !items-start" direction="horizontal">
          <FormItem
            name="start_time"
            label="Data (GMT-03:00)"
            rules={[{ required: true, message: 'A data é obrigatória' }]}
          >
            <DatePicker
              showTime
              format={'DD/MM/YYYY HH:mm'}
              placeholder="dd/mm/aaaa hh:mm"
            />
          </FormItem>
          <Space className="mt-1">
            <Typography.Text className="ml-2 !text-[#00000073]">
              Agendar com 24 horas de antecedência
            </Typography.Text>
          </Space>
        </Space>

        <FormItem
          label="Duração"
          name="duration_in_minutes"
          rules={[{ required: true, message: 'A duração é obrigatória' }]}
        >
          <Select>
            {getTimeOptions().map(option => (
              <Select.Option key={option.value} value={option.value}>
                {option.label}
              </Select.Option>
            ))}
          </Select>
        </FormItem>

        <FormItem
          name="professor_ids"
          label="Professor"
          rules={[{ required: true, message: 'O professor é obrigatório' }]}
        >
          <Select {...professorSelectProps} mode="multiple" />
        </FormItem>
      </Form>
    </Modal>
  );
};
