import { IResource } from 'interfaces/resources';
import { TeamOutlined } from '@ant-design/icons';
import { generateResources } from 'utils/GenerateResouces';

const resource: IResource = {
  name: 'professor_area',
  label: 'Área do professor',
  icon: <TeamOutlined />
};

export default generateResources(resource);
