import * as Icons from '@ant-design/icons';
import VideoPreviewModal from 'components/modal/VideoPreviewModal';
import { BaseKey, useList, useModal } from '@refinedev/core';
import {
  Button,
  Divider,
  Empty,
  Radio,
  Row,
  Space,
  Spin,
  Switch,
  Typography
} from 'antd';
import { CreateClassModal } from 'components/modal/professor_area/classroom/CreateClassModal';
import { IDamVideo } from 'interfaces/dam_videos';
import { IInteractiveClass } from 'interfaces/interactive_classes';
import { IProfessorAreaClassroom } from 'interfaces/professor_area_classrooms';
import { ShowClassModal } from 'components/modal/professor_area/classroom/ShowClassModal';
import { TableRowItem } from './TableRowItem';
import { getUserId } from 'services/auth';
import { useCallback, useState } from 'react';
import { useModalForm } from '@refinedev/antd';

export interface TabInteractiveClassesProps {
  classroom: IProfessorAreaClassroom;
}

export const TabInteractiveClasses = ({
  classroom
}: TabInteractiveClassesProps) => {
  const resource = 'interactive_classes';
  const [editModal, setEditModal] = useState(false);
  const [videoData, setVideoData] = useState<IDamVideo>();
  const [filter, setFilter] = useState('progress');
  const [filterMyClasses, setFilterMyClasses] = useState(false);
  const videoDetailsModal = useModal();

  const { data, isLoading, refetch } = useList({
    resource: 'professor_area/interactive_classes',
    filters: [
      {
        field: 'filter[by_classroom_id]',
        operator: 'eq',
        value: classroom.id
      },
      {
        field: 'filter[by_professor_id]',
        operator: 'eq',
        value: filterMyClasses ? getUserId() : undefined
      },
      {
        field: 'filter[by_status]',
        operator: 'eq',
        value: filter === 'finished' ? 'finished' : undefined
      }
    ]
  });
  const interactiveClasses = data?.data as IInteractiveClass[];
  const {
    modalProps: createModalProps,
    formProps: createFormProps,
    show: createModalShow
  } = useModalForm<IInteractiveClass>({
    resource: 'professor_area/interactive_classes',
    action: 'create',
    redirect: false
  });

  const {
    modalProps: showModalProps,
    formProps: showFormProps,
    queryResult: showQuery,
    show: showModalShow,
    close: closeModalShow
  } = useModalForm<IInteractiveClass>({
    resource,
    action: 'edit',
    redirect: false,
    warnWhenUnsavedChanges: false
  });

  const handleShowPreviewVideo = useCallback(
    (videoData: IDamVideo) => () => {
      setVideoData(videoData);
      videoDetailsModal.show();
    },
    [videoDetailsModal]
  );

  const handleShowOrEdit = useCallback(
    (isEdit: boolean, id?: BaseKey) => () => {
      setEditModal(isEdit);
      showModalShow(id);
    },
    [showModalShow]
  );

  return (
    <>
      <Space direction="vertical" className="w-full !gap-8">
        <Space className="flex w-full p-2 rounded-md bg-[#F5F5F5]">
          <Radio.Group
            buttonStyle="solid"
            value={filter}
            onChange={e => {
              setFilter(e.target.value);
            }}
          >
            <Radio.Button value="progress">Próximas aulas</Radio.Button>
            <Radio.Button value="finished">Aulas realizadas</Radio.Button>
          </Radio.Group>
          <Switch
            value={filterMyClasses}
            defaultValue={filterMyClasses}
            onChange={() => setFilterMyClasses(!filterMyClasses)}
            className="!ml-1"
          />
          <Typography.Paragraph className="!m-0">
            Minhas aulas
          </Typography.Paragraph>
        </Space>
        <Space className="flex w-full justify-between">
          <Space>
            <Typography.Title level={5} className="!m-0">
              Próximas aulas
            </Typography.Title>
            <Space className="flex rounded border solid p-1 h-[22px] w-[24px] justify-center bg-[#00000002]">
              {interactiveClasses?.length ?? undefined}
            </Space>
          </Space>
          <Button
            type="primary"
            icon={<Icons.PlusOutlined />}
            onClick={() => createModalShow()}
            size="small"
          >
            Nova aula
          </Button>
        </Space>
      </Space>

      <Divider className="w-full !mb-0" />
      {isLoading && (
        <Row className="w-full flex justify-center">
          <Spin className="self-center mt-8" />
        </Row>
      )}
      {interactiveClasses?.map((interactiveClass, index) => (
        <TableRowItem
          key={index.toString()}
          showModal={handleShowOrEdit}
          previewModal={handleShowPreviewVideo}
          interactiveClass={interactiveClass}
          refetch={refetch}
        />
      ))}
      {interactiveClasses && interactiveClasses.length === 0 && (
        <Row className="w-full flex justify-center">
          <Empty description="Esta turma não possui aulas interativas." />
        </Row>
      )}

      <CreateClassModal
        modalProps={createModalProps}
        formProps={createFormProps}
      />
      {!showQuery?.isLoading && (
        <ShowClassModal
          isEdit={editModal}
          modalProps={showModalProps}
          formProps={showFormProps}
          closeModal={closeModalShow}
          previewModal={handleShowPreviewVideo}
          refetch={refetch}
        />
      )}
      {videoData && (
        <VideoPreviewModal modal={videoDetailsModal} video={videoData} />
      )}
    </>
  );
};
