import { Card, Space, Typography } from 'antd';
import { CrudFilters, HttpError, useParsed } from '@refinedev/core';
import {
  DiscussionThreadsFilter,
  IDiscussionThreadsFilter
} from './components/list-filter';
import { DiscussionThreadsTable } from './components/list-table';
import { ITeacherDiscussionThread } from 'interfaces/teacher_discussion_threads';
import { List, useTable } from '@refinedev/antd';
import { QuestionCircleOutlined } from '@ant-design/icons';

export const DiscussionThreadList = () => {
  const { params } = useParsed();
  const initialValues = { ...params?.filter };

  const { tableProps, searchFormProps } = useTable<
    ITeacherDiscussionThread,
    HttpError,
    IDiscussionThreadsFilter
  >({
    filters: {
      initial: [
        {
          field: 'filter[parent_reference]',
          operator: 'eq',
          value: params?.parent_reference
        },
        {
          field: 'filter[by_user_name]',
          operator: 'eq',
          value: initialValues?.by_user_name
        }
      ]
    },
    onSearch: params => {
      const filters: CrudFilters = [];
      const { by_grandparent_id, by_status, by_user_name, by_post_text } =
        params;

      filters.push(
        {
          field: 'filter[by_post_text]',
          operator: 'eq',
          value: by_post_text
        },
        {
          field: 'filter[by_grandparent_id]',
          operator: 'eq',
          value: by_grandparent_id
        },
        {
          field: 'filter[by_status]',
          operator: 'eq',
          value: by_status
        },
        {
          field: 'filter[by_user_name]',
          operator: 'eq',
          value: by_user_name
        }
      );

      return filters;
    }
  });

  return (
    <List
      breadcrumb={false}
      headerProps={{
        title: false,
        className: '[&>*]:pt-0'
      }}
      headerButtonProps={{
        hidden: true
      }}
    >
      <Card className="!mb-4">
        <Space size="middle" className="items-center">
          <div className="bg-primary-50 p-4 rounded-lg flex items-center justify-center">
            <QuestionCircleOutlined className="text-2xl text-primary-500" />
          </div>
          <Typography.Title level={3} className="flex-1 !mb-0">
            Tira-dúvidas
          </Typography.Title>
        </Space>
      </Card>

      <Card>
        <DiscussionThreadsFilter
          searchFormProps={{ ...searchFormProps, initialValues }}
        />
        <DiscussionThreadsTable tableProps={tableProps} />
      </Card>
    </List>
  );
};
