import { useCustom } from '@refinedev/core';

export const ModalContent = ({ gatewayId }: { gatewayId: string }) => {
  const { data, isLoading, isError } = useCustom({
    url: `/admin/franchises/${gatewayId}/authentication_link`,
    method: 'get',
    queryOptions: {
      retry: 0
    },
    errorNotification: false
  });

  return (
    <div>
      {isLoading && <span>Carregando...</span>}
      {isError && <span>Erro ao carregar link</span>}
      {data?.data?.data && (
        <a href={data?.data?.data} target="_blank" rel="noreferrer">
          Link de Autenticação
        </a>
      )}
    </div>
  );
};
