import {
  Edit,
  ListButton,
  RefreshButton,
  useForm,
  useSelect
} from '@refinedev/antd';
import { Form, Input, InputNumber, Select, Typography } from 'antd';
import { useSearchParams } from 'react-router-dom';
import { useShow } from '@refinedev/core';

const { Text } = Typography;

export const FranchiseManagementEdit = () => {
  const { selectProps: getaweysSelectProps } = useSelect({
    resource: 'franchises',
    optionLabel: 'name',
    optionValue: 'id'
  });

  const { selectProps: getaweysRulesSelectProps } = useSelect({
    resource: 'offers/gateways/rules',
    optionLabel: 'name',
    optionValue: 'id'
  });

  const { selectProps: fiscaTemplatesSelectProps } = useSelect({
    resource: 'fiscal_templates',
    optionLabel: 'name',
    optionValue: 'id'
  });

  const { formProps, saveButtonProps } = useForm();

  const { queryResult } = useShow();
  const [searchParams] = useSearchParams();
  const index = searchParams.get('index');

  const data = {
    gateway_id: queryResult.data?.data.gateway_id,
    ...queryResult.data?.data?.range_groups[index!],
    ranges: queryResult.data?.data?.range_groups[index!]?.range
  };

  return (
    <Edit
      title="Editar Regra de Franquia"
      saveButtonProps={{ ...saveButtonProps, disabled: !index }}
      headerButtons={() => (
        <>
          <ListButton />
          <RefreshButton />
        </>
      )}
    >
      {index ? (
        <Form {...formProps} layout="vertical" initialValues={data}>
          <Form.Item
            label="Gateway"
            name="gateway_id"
            rules={[{ required: true }]}
          >
            <Select {...getaweysSelectProps} disabled />
          </Form.Item>
          <Form.Item
            label="Regra"
            name={'rule_id'}
            rules={[{ required: true }]}
          >
            <Select {...getaweysRulesSelectProps} />
          </Form.Item>
          <Form.Item
            label="Template Fiscal"
            name={'fiscal_template_id'}
            rules={[{ required: true }]}
          >
            <Select {...fiscaTemplatesSelectProps} />
          </Form.Item>
          <Form.Item
            label="Marketplace Fee"
            name={['marketplace_fee', 'percentage']}
            rules={[{ required: true }]}
          >
            <InputNumber min={0} max={100} />
          </Form.Item>
          <Text
            style={{
              fontSize: '14px',
              fontWeight: '500',
              display: 'flex',
              paddingBottom: '8px'
            }}
          >
            <span
              style={{
                fontSize: '10px',
                marginInlineEnd: '4px',
                color: '#ef4444'
              }}
            >
              *
            </span>
            Faixas de CEP
          </Text>
          <Form.List name="ranges">
            {fields => (
              <div
                style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}
              >
                {fields.map(({ key, name, ...restField }) => (
                  <div
                    key={key}
                    style={{
                      backgroundColor: '#f9fafb',
                      padding: '16px',
                      borderRadius: '8px'
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        gap: '8px',
                        justifyContent: 'space-between'
                      }}
                    >
                      <Text
                        style={{ fontSize: '14px', fontWeight: 500 }}
                      >{`Faixa de CEP ${name + 1}`}</Text>
                    </div>
                    <Form.Item
                      {...restField}
                      style={{ marginBottom: '8px' }}
                      normalize={value => value.replace(/\D/g, '').slice(0, 8)}
                      label={
                        <label
                          style={{
                            fontWeight: 400,
                            fontSize: '12px'
                          }}
                        >
                          Inicial
                        </label>
                      }
                      name={[name, 'initial']}
                      rules={[
                        {
                          required: true,
                          message: 'Campo obrigatório'
                        }
                      ]}
                    >
                      <Input placeholder="CEP do endereço (somente números)" />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      style={{ marginBottom: 'none' }}
                      normalize={value => value.replace(/\D/g, '').slice(0, 8)}
                      label={
                        <label
                          style={{
                            fontWeight: 400,
                            fontSize: '12px'
                          }}
                        >
                          Final
                        </label>
                      }
                      name={[name, 'final']}
                      rules={[
                        {
                          required: true,
                          message: 'Campo obrigatório'
                        }
                      ]}
                    >
                      <Input placeholder="CEP do endereço (somente números)" />
                    </Form.Item>
                  </div>
                ))}
              </div>
            )}
          </Form.List>
        </Form>
      ) : (
        <Text>
          Selecione um conjunto de regras na lista de{' '}
          <strong>Gestão de Franquias</strong>...
        </Text>
      )}
    </Edit>
  );
};
