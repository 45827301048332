import Icon, {
  CommentOutlined,
  EllipsisOutlined,
  EyeOutlined,
  FileOutlined,
  FilePdfOutlined,
  FileProtectOutlined,
  PlaySquareOutlined
} from '@ant-design/icons';
import clsx from 'clsx';
import { Button, Dropdown, MenuProps, Space, Typography } from 'antd';
import { htmlIcon } from 'assets/icons/html';
import { useNavigation } from '@refinedev/core';
import { useState } from 'react';
import type { IDataContent, IDataNode } from 'interfaces/courses';

interface ContentTreeNodeProps {
  node: IDataNode | IDataContent;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ICON_BY_KIND_MAP: Record<string, any> = {
  video: PlaySquareOutlined,
  hyper_text: htmlIcon,
  pdf: FilePdfOutlined,
  content: FileOutlined,
  lti: FileProtectOutlined
};

export const ContentTreeNode = ({ node }: ContentTreeNodeProps) => {
  const { push, listUrl } = useNavigation();
  const [hovered, setHover] = useState(false);

  if (node.isLeaf) {
    const menuProps: MenuProps = {
      items: [
        {
          label: 'Ver Tira-dúvidas',
          key: 'show-threads',
          icon: <CommentOutlined />,
          onClick: () => {
            const uri = listUrl('professor_area/discussion_threads');

            push(`${uri}?parent_reference=${node?.key}`);
          }
        }
        // TODO - Implementar modal de visualização de aula
        // {
        //   label: 'Visualizar aula',
        //   key: 'show-content',
        //   icon: <EyeOutlined />,
        //   onClick: () => {
        //     return;
        //   }
        // },
        // TODO - Implementar modal de visualização de material complementar
        // {
        //   label: 'Ver Material complementar',
        //   key: 'show-supplementary-material',
        //   icon: <FileOutlined />,
        //   onClick: () => {
        //     return;
        //   }
        // }
      ]
    };

    return (
      <Space
        className={clsx(
          'group w-full items-center py-4 px-2 justify-between border-t border-t-neutral-100',
          hovered && 'bg-neutral-100'
        )}
      >
        <Space className="items-center">
          <div className="w-10 h-10 bg-primary-100 p-2 rounded-lg flex items-center justify-center">
            <Icon
              component={ICON_BY_KIND_MAP[(node as IDataContent).core_kind]}
              className="text-xl text-primary-500"
            />
          </div>

          <div>
            <Typography.Text strong className="text-base font-semibold block">
              {node.title?.toString()}
            </Typography.Text>

            {/* TODO: Next sprint
              <Space>
                <Space>
                  <EyeOutlined />
                  <Typography.Text className="text-sm">
                    58% visualizaram
                  </Typography.Text>
                </Space>
                <Space>
                  <CommentOutlined />
                  <Typography.Text className="text-sm">9</Typography.Text>
                </Space>
                <Space>
                  <FileOutlined />
                  <Typography.Text className="text-sm">0</Typography.Text>
                </Space>
              </Space>
            */}
          </div>
        </Space>

        {/* TODO - Implementar acões para dropdown */}
        <Dropdown
          menu={menuProps}
          trigger={['click']}
          onOpenChange={setHover}
          className={clsx(
            'opacity-0 group-hover:opacity-100 group-active:opacity-100',
            { 'opacity-100': hovered }
          )}
        >
          <Button icon={<EllipsisOutlined />} />
        </Dropdown>
      </Space>
    );
  }

  return (
    <Typography.Text strong className="!text-base mb-0 !py-4">
      {node.title?.toString()}
    </Typography.Text>
  );
};
