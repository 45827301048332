import FilterForm from 'components/forms/FilterForm';
import { Col, Form, Input, Modal, Row, Select, Space, Table } from 'antd';
import {
  CrudFilters,
  HttpError,
  useModal,
  useNavigation
} from '@refinedev/core';
import {
  EditButton,
  List,
  RefreshButton,
  TextField,
  useTable
} from '@refinedev/antd';
import { IFranchises } from 'interfaces/franchises';
import { ModalContent } from './components/ModalContent';
import { useState } from 'react';

interface IFilter {
  id: string;
  name: string;
  seller_status: string;
}

export const FranchiseManagementList = () => {
  const [modalTitle, setModalTitle] = useState('');
  const [modalGatewayId, setModalGatewayId] = useState('');
  const { push } = useNavigation();
  const { show, close, visible } = useModal();

  const isEditable = (record: IFranchises) => {
    switch (true) {
      case record.seller_status === 'waiting': {
        return true;
      }

      case record.franchise_status !== 'inactive' &&
        record.seller_status !== 'inactive': {
        return true;
      }

      default: {
        return false;
      }
    }
  };

  const editGateway = (record: IFranchises) => {
    if (record.seller_status === 'waiting') {
      setModalTitle(record.name);
      setModalGatewayId(record.id);
      show();
    }

    if (record.seller_status === 'active') {
      push(`/finance/franchises/show/${record.id}`);
    }
  };

  const { searchFormProps, tableProps } = useTable<
    IFranchises,
    HttpError,
    IFilter
  >({
    resource: 'franchises',

    pagination: {
      mode: 'server'
    },

    onSearch: params => {
      const filters: CrudFilters = [];
      const { name, seller_status } = params;
      filters.push(
        {
          field: 'filter[name]',
          operator: 'eq',
          value: name
        },
        {
          field: 'filter[status]',
          operator: 'eq',
          value: seller_status
        }
      );

      return filters;
    }
  });

  const optionsStatusSellerEnum = {
    active: 'Habilitado para pagamento',
    waiting: 'Aguardando autenticação',
    inactive: 'Desativado para pagamento'
  };

  const optionsStatusSeller = [
    {
      label: 'Habilitado para pagamento',
      value: 'active'
    },
    {
      label: 'Aguardando autenticação',
      value: 'waiting'
    },
    {
      label: 'Desativado para pagamento',
      value: 'inactive'
    }
  ];

  const statusFranchiseEnum = {
    active: 'Ativo',
    inactive: 'Inativo'
  };

  return (
    <List headerButtons={<RefreshButton />}>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <FilterForm searchFormProps={searchFormProps}>
            <Col span={6}>
              <Form.Item label="Nome" name="name">
                <Input placeholder="Nome" />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="Status Seller" name="seller_status">
                <Select placeholder="Status" options={optionsStatusSeller} />
              </Form.Item>
            </Col>
          </FilterForm>
          <Table {...tableProps} rowKey={(_, index) => index!}>
            <Table.Column
              dataIndex="name"
              key="name"
              title="Nome do Gateway"
              render={value => <TextField value={value} />}
            />
            <Table.Column
              dataIndex="seller_status"
              key="seller_status"
              title="Status Seller"
              render={value => (
                <TextField
                  value={
                    optionsStatusSellerEnum[
                      value as keyof typeof optionsStatusSellerEnum
                    ]
                  }
                />
              )}
            />
            <Table.Column
              dataIndex="franchise_status"
              key="franchise_status"
              title="Status Franquia"
              render={value => (
                <TextField
                  value={
                    statusFranchiseEnum[
                      value as keyof typeof statusFranchiseEnum
                    ]
                  }
                />
              )}
            />
            <Table.Column
              dataIndex="actions"
              title="Ações"
              render={(_, record: IFranchises) => {
                return (
                  <Space>
                    <EditButton
                      disabled={!isEditable(record)}
                      hideText
                      size="small"
                      recordItemId={record.id}
                      onClick={() => editGateway(record)}
                    />
                  </Space>
                );
              }}
            />
          </Table>
        </Col>
        <Modal open={visible} onCancel={close} title={modalTitle} footer={null}>
          <ModalContent gatewayId={modalGatewayId} />
        </Modal>
      </Row>
    </List>
  );
};
