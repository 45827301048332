import React, { useCallback, useState } from 'react';
import clsx from 'clsx';
import { Button, Dropdown, MenuProps, Modal, Space, Typography } from 'antd';
import {
  CalendarOutlined,
  CommentOutlined,
  DeleteOutlined,
  EllipsisOutlined,
  ExclamationCircleOutlined,
  EyeOutlined,
  MessageOutlined
} from '@ant-design/icons';
import { IForum } from 'interfaces/forum';
import { MenuInfo } from 'rc-menu/lib/interface';
import { TagField } from '@refinedev/antd';
import { formatDate } from 'services/date';
import { getForumTagData } from 'services/professor_area/forum';
import { useDelete, useNavigation } from '@refinedev/core';

const { Text } = Typography;
const { confirm } = Modal;

const items: MenuProps['items'] = [
  {
    key: 'show',
    label: 'Visualizar tópico',
    icon: <EyeOutlined />
  },
  {
    key: 'close',
    label: 'Fechar tópico',
    icon: <DeleteOutlined />
  }
];

export const ForumListItem: React.FC<{
  forum: IForum;
  refetch: () => void;
}> = ({ forum, refetch }) => {
  const [hovered, setHover] = useState(false);
  const { push } = useNavigation();
  const { mutateAsync } = useDelete();
  const tagData = getForumTagData(forum);

  const handleCloseForum = useCallback(() => {
    confirm({
      title: 'Deseja fechar esse tópico?',
      content: 'Essa ação não pode ser revertida',
      icon: <ExclamationCircleOutlined style={{ color: 'red' }} />,
      okButtonProps: { type: 'primary', danger: true },
      okText: 'Fechar tópico',
      cancelText: 'Voltar',
      onOk: () => {
        mutateAsync({
          resource: 'professor_area/forums',
          id: forum.id,
          successNotification: () => ({
            type: 'success',
            description: 'Sucesso!',
            message: 'Forum fechado com sucesso'
          }),
          errorNotification: () => ({
            type: 'error',
            description: 'Ops!',
            message: 'Não foi possível fechar este forum. Tente novamente.'
          })
        })
          .then(() => {
            refetch();
          })
          .catch();
      }
    });
  }, [forum, mutateAsync, refetch]);

  const handleDropdown = useCallback(
    (event: MenuInfo) => {
      if (event.key === 'show') {
        push(`/professor_area/forums/show/${forum.id}`);
      } else {
        handleCloseForum();
      }
    },
    [forum, push, handleCloseForum]
  );

  return (
    <div className="group flex w-full py-4 px-2 justify-between hover:bg-neutral-100 border-b-[1px] border-solid border-color-bg-neutral-100 first:border-t-[1px] first:border-solid first:border-color-bg-neutral-100">
      <Space>
        <Space
          direction="vertical"
          className="flex rounded-md w-[56px] h-[56px] p-2 bg-[#a855f7]/10 justify-center items-center !gap-0"
        >
          <CommentOutlined className="text-[#9333ea] text-2xl" />
        </Space>
        <Space direction="vertical" className="gap-0">
          <Text strong>{forum.title}</Text>
          <Space className="gap-4">
            <Text type="secondary">{forum.author.name}</Text>
            <Space className="gap-1">
              <MessageOutlined />
              <Text type="secondary">{forum.posts_count ?? 0}</Text>
            </Space>
            <Space className="gap-1">
              <CalendarOutlined />
              <Text type="secondary">
                {formatDate(forum.created_at, 'DD/MM/YYYY')}
              </Text>
            </Space>
          </Space>
        </Space>
      </Space>
      <Space align="center">
        <Space>
          <TagField color={tagData.color} value={tagData.label} />
        </Space>
        <Dropdown
          menu={{ items, onClick: handleDropdown } as MenuProps}
          placement="bottomLeft"
          onOpenChange={setHover}
          className={clsx(
            'opacity-0 group-hover:opacity-100 group-active:opacity-100',
            hovered && 'opacity-100'
          )}
        >
          <Button icon={<EllipsisOutlined />} />
        </Dropdown>
      </Space>
    </div>
  );
};
