import {
  FranchiseManagementCreate,
  FranchiseManagementEdit,
  FranchiseManagementList,
  FranchiseManagementShow
} from 'pages/franchise_management';
import { generateResources } from 'utils/GenerateResouces';

const resource = {
  name: 'franchises',
  label: 'Gestão de Franquias',
  list: FranchiseManagementList,
  show: FranchiseManagementShow,
  edit: FranchiseManagementEdit,
  create: FranchiseManagementCreate,
  actions: ['list', 'show', 'edit', 'create'],
  parentName: 'finance'
};

export default generateResources(resource);
