import React from 'react';
import { Avatar, Form, InputNumber, Modal, Space, Typography } from 'antd';
import { IStudentShow } from 'interfaces/professor_area/students';
import { getAPI } from 'requests/api';
import { getInitialsName } from 'utils/get-initials-name';
import { useModal, useNotification } from '@refinedev/core';
import { useParams } from 'react-router-dom';

interface modalProps {
  modalProps: ReturnType<typeof useModal>;
  student?: IStudentShow;
  refetch: () => void;
  onUpdateStudent: (student: IStudentShow) => void;
}

export const EditFrequencyModal: React.FC<modalProps> = ({
  modalProps,
  student,
  refetch,
  onUpdateStudent
}) => {
  const [form] = Form.useForm();
  const { id } = useParams();
  const notification = useNotification();

  if (!student) {
    return null;
  }

  const handleSave = () => {
    form.validateFields().then((values: IStudentShow) => {
      getAPI()
        .patch(
          `/admin/professor_area/classrooms/${id}/enrollments/${student.id}/update_frequency`,
          {
            attendance_rate: values.attendance_rate
          }
        )
        .then(() => {
          notification.open?.({
            type: 'success',
            description: 'Sucesso!',
            message: 'Frequência atualizada com sucesso!'
          });
          refetch();
          onUpdateStudent({ ...student, ...values });

          form.resetFields();
          modalProps.close();
        })
        .catch(() => {
          notification.open?.({
            type: 'error',
            description: 'Ops!',
            message: 'Não foi possível atualizar a frequência do aluno.'
          });
        });
    });
  };

  const handleCancel = () => {
    form.resetFields();
    modalProps.close();
  };

  return (
    <Modal
      title="Editar Frequência"
      width={412}
      open={modalProps.visible}
      onCancel={handleCancel}
      onOk={handleSave}
      okText="Salvar"
      styles={{
        footer: {
          marginTop: 32
        }
      }}
      cancelText="Cancelar"
      centered
      zIndex={1001}
    >
      <Space direction="vertical" className="w-full mt-2 gap-4">
        <Space>
          <Avatar className="bg-[#a855f7]/10 text-[#9333ea]" size={40}>
            {getInitialsName(student.student_name)}
          </Avatar>
          <div>
            <div className="font-bold">{student.student_name}</div>
            <Typography.Text type="secondary">
              Matrícula: {student.enrollment_registration}
            </Typography.Text>
          </div>
        </Space>
        <Form form={form} initialValues={{ ...student }} layout="inline">
          <Space className="gap-4">
            <Typography.Text type="secondary" className="!m-0">
              Frequência
            </Typography.Text>
            <Form.Item
              name="attendance_rate"
              rules={[
                {
                  required: true,
                  message: 'Por favor, insira a frequência!'
                },
                {
                  type: 'number',
                  min: 0,
                  max: 100,
                  message: 'A frequência deve estar entre 0 e 100!'
                }
              ]}
            >
              <InputNumber
                type="number"
                placeholder="0-100"
                addonAfter={<Typography.Text>%</Typography.Text>}
                inputMode="numeric"
                className="w-[110px]"
              />
            </Form.Item>
          </Space>
        </Form>
      </Space>
    </Modal>
  );
};
