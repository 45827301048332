import { IResource } from 'interfaces/resources';
import { TopicShow } from 'pages/teacher_space/classrooms/components/TabForum/components/Topics/TopicShow';
import { generateResources } from 'utils/GenerateResouces';

const resource: IResource = {
  name: 'professor_area/forums',
  label: 'Fórums',
  show: TopicShow,
  actions: ['show', 'list', 'edit', 'create', 'delete'],
  parentName: 'professor_area',
  route: 'professor_area/forums'
};

export default generateResources(resource);
