import { INotificationContext } from '@refinedev/core';
import { getAPI } from 'requests/api';

export const downloadReportFile = async (
  interactiveClassId: string,
  notification: INotificationContext
) => {
  try {
    const response = await getAPI().get(
      `/admin/interactive_classes/${interactiveClassId}/participants_report`,
      {
        responseType: 'blob'
      }
    );

    const url = window.URL.createObjectURL(new Blob([response.data]));

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'relatorio_participantes.csv');
    document.body.append(link);
    link.click();

    window.URL.revokeObjectURL(url);
    link.remove();
  } catch (error: any) {
    if (error?.status === 404) {
      notification.open?.({
        type: 'error',
        message: 'Download Error',
        description: 'Não há relatório de participantes disponível.'
      });
    } else {
      notification.open?.({
        type: 'error',
        message: 'Download Error',
        description: 'Erro ao baixar o relatório de participantes.'
      });
    }
  }
};

export const getStatusColor = (status: string) => {
  switch (status) {
    case 'created': {
      return 'purple';
    }

    case 'started': {
      return 'blue';
    }

    case 'finished': {
      return 'default';
    }

    case 'cancelled': {
      return 'red';
    }

    default: {
      return 'default';
    }
  }
};

export const translateStatus = (status: string) => {
  switch (status) {
    case 'created': {
      return 'Agendada';
    }

    case 'started': {
      return 'Em andamento';
    }

    case 'finished': {
      return 'Concluída';
    }

    case 'cancelled': {
      return 'Cancelada';
    }

    default: {
      return status;
    }
  }
};

export const getTimeOptions = () => {
  return Array.from({ length: 24 }, (_, index) => {
    const totalMinutes = (index + 1) * 30; // Generate 30, 60, 90, ..., 720
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;

    const label = [
      hours > 0 ? `${hours} hora${hours > 1 ? 's' : ''}` : null,
      minutes > 0 ? `${minutes} minutos` : null
    ]
      .filter(Boolean)
      .join(' e '); // Combine hours and minutes, e.g., "1 hora e 30 minutos"

    return { value: totalMinutes, label };
  });
};
