import { IForum } from 'interfaces/forum';
import { IProfessorAreaPostsForum } from 'interfaces/professor_area/forums';
import { forumStatusOptions } from 'constants/admin/forum';

export const getForumTagData = (
  forum: IProfessorAreaPostsForum | IForum | undefined
) => {
  const tempData: {
    label: string;
    color: string;
  } = {
    label:
      forumStatusOptions.find(option => option.value === forum?.status)
        ?.label ?? 'Default',
    color:
      forumStatusOptions.find(option => option.value === forum?.status)
        ?.color ?? 'Default'
  };

  if (forum?.deleted_at) {
    tempData.label = 'Excluído';
    tempData.color = 'red';
  }

  return tempData;
};
