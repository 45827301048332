import { Button, Popover, Table, TableColumnProps, Typography } from 'antd';
import {
  CreateButton,
  EditButton,
  ListButton,
  RefreshButton,
  Show
} from '@refinedev/antd';
import { IRange } from 'interfaces/franchises';
import {
  useCustomMutation,
  useNavigation,
  useParsed,
  useShow
} from '@refinedev/core';
import { useState } from 'react';

const { Title } = Typography;

export const FranchiseManagementShow = () => {
  const { queryResult } = useShow();
  const record = queryResult.data?.data;
  const gatewayId = record?.gateway_id;

  const navigate = useNavigation();

  const handleEdit = async (index: number) => {
    navigate.push(`/finance/franchises/edit/${gatewayId}?index=${index}`);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const colums: TableColumnProps<any>[] = [
    {
      title: 'ID da Regra',
      dataIndex: 'rule_id'
    },
    {
      title: 'Fiscal Template',
      dataIndex: 'fiscal_template_id'
    },
    {
      title: 'Marketplace Fee',
      dataIndex: 'marketplace_fee',
      render: marketplace_fee => <span>{marketplace_fee.percentage}%</span>
    },
    {
      title: 'Ranges',
      dataIndex: 'range',
      render: ranges => (
        <>
          {ranges.map((range: IRange, index: number) => (
            <span key={index}>
              {range.initial} - {range.final}
              <br />
            </span>
          ))}
        </>
      )
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record, index) => (
        <EditButton
          onClick={() => {
            handleEdit(index);
          }}
        />
      )
    }
  ];

  return (
    <>
      <Show
        title="Visualizar regras de gateway"
        isLoading={queryResult.isLoading}
        canEdit={false}
        headerButtons={
          <>
            <CreateButton>Novo Grupo de Regras</CreateButton>
            <DangerButton />
            <ListButton />
            <RefreshButton />
          </>
        }
      >
        {record?.range_groups && (
          <>
            <Title level={5}>Lista de Regras</Title>
            <Table
              dataSource={record.range_groups}
              columns={colums}
              rowKey={(_, index) => index!}
            />
          </>
        )}
      </Show>
    </>
  );
};

export const DangerButton = () => {
  const [open, setOpen] = useState(false);
  const { id: gateway_id } = useParsed();
  const { mutate } = useCustomMutation();
  const { list } = useNavigation();

  const handleCancel = async (kind: 'partial' | 'total') => {
    await mutate({
      url: `/admin/franchises/${gateway_id}/inactivate`,
      method: 'put',
      values: {
        kind
      },
      successNotification: () => {
        list('franchises');
        return {
          message: 'Franquia desativada com sucesso',
          description: `${
            kind === 'total' ? 'Todos os' : 'Novos'
          } pedidos foram movidos para a matriz`,
          type: 'success'
        };
      },
      errorNotification: () => {
        setOpen(false);
        return {
          message: 'Erro ao desativar franquia',
          description: 'Tente novamente mais tarde',
          type: 'error'
        };
      }
    });
  };

  const popoverContent = (
    <div
      style={{ display: 'flex', flexDirection: 'column', maxWidth: '600px' }}
    >
      <Title level={5}>Desativar Franquia?</Title>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <span>Atenção:</span>
        <ul style={{ paddingLeft: '20px' }}>
          <li>
            <strong>Todos os pedidos</strong>: novos pagamentos realizados,
            sejam de pedidos antigos ou novos, serão transferidos para a matriz.
          </li>

          <li>
            <strong>Novos pedidos</strong>: apenas o pagamento de novos pedidos
            serão movidos para a matriz, mantendo pedidos antigos atrelados à
            franquia.
          </li>
        </ul>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          gap: '8px',
          marginTop: '12px',
          justifyContent: 'flex-end'
        }}
      >
        <Button type="default" onClick={() => handleCancel('total')}>
          Desativar e mover todos
        </Button>
        <Button
          type="default"
          value={'partial'}
          onClick={() => handleCancel('partial')}
        >
          Desativar e mover novos
        </Button>
        <Button type="primary" onClick={() => setOpen(false)}>
          Cancelar
        </Button>
      </div>
    </div>
  );
  return (
    <Popover
      trigger="click"
      placement="bottom"
      content={popoverContent}
      open={open}
    >
      <Button
        style={{ color: '#ef4444', borderColor: '#ef4444' }}
        onClick={() => setOpen(true)}
      >
        Desativar
      </Button>
    </Popover>
  );
};
