import { ITeacherDiscussionThreadStatus } from 'interfaces/teacher_discussion_threads';

export * from './list';
export * from './show';

const DISCUSSION_STATUS_MAP = {
  pending: 'Não resolvida',
  answered: 'Respondida',
  closed: 'Encerrada',
  unknown: 'Desconhecida'
};

export const translateDiscussionStatus = (
  text?: ITeacherDiscussionThreadStatus
) => {
  return DISCUSSION_STATUS_MAP[text ?? 'unknown'];
};
