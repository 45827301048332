import { FilterNoticeBoard, NoticeBoardFilter } from './Filter';
import { Flex } from 'antd';
import { IProfessorAreaNoticeBoardShow } from 'interfaces/professor_area/notice_boards';
import { NoticeBoardList } from './List';
import { useCallback, useState } from 'react';
import { useList } from '@refinedev/core';
import { useParams } from 'react-router-dom';

export const ITEMS_PER_PAGE = 5;

export const TabNoticeBoard = () => {
  const params = useParams();
  const classroomId = params.id;
  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState<FilterNoticeBoard>({
    by_priority: undefined,
    by_title: ''
  });

  const { data, isFetching, refetch } = useList<IProfessorAreaNoticeBoardShow>({
    resource: 'professor_area/notice_boards',
    filters: [
      {
        field: 'filter[by_classroom_id]',
        operator: 'eq',
        value: classroomId
      },
      {
        field: 'filter[by_title]',
        operator: 'eq',
        value: filters.by_title
      },
      {
        field: 'filter[by_priority]',
        operator: 'eq',
        value: filters.by_priority
      },
      {
        field: 'page',
        operator: 'eq',
        value: page
      },
      {
        field: 'items',
        operator: 'eq',
        value: ITEMS_PER_PAGE
      }
    ]
  });

  const handleClear = () => {
    setFilters({
      by_priority: undefined,
      by_title: ''
    });
    setPage(1);
  };

  const handleSearch = useCallback(
    (formData: FilterNoticeBoard) => {
      setFilters(formData);
      setPage(1);
      refetch();
    },
    [refetch]
  );

  const handleChangePage = useCallback(
    (page: number) => {
      setPage(page);
      refetch();
    },
    [refetch]
  );
  return (
    <Flex vertical>
      <NoticeBoardFilter
        onClear={handleClear}
        onSearch={handleSearch}
        isLoading={isFetching}
      />
      <NoticeBoardList
        notices={data?.data}
        page={page}
        total={data?.total}
        itemsPerPage={ITEMS_PER_PAGE}
        onChangePage={handleChangePage}
        refetch={refetch}
      />
    </Flex>
  );
};
