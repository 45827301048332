import clsx from 'clsx';
import { Button, Divider, Dropdown, Modal, Space, Typography } from 'antd';
import {
  DeleteOutlined,
  DownloadOutlined,
  EllipsisOutlined,
  ExclamationCircleOutlined,
  EyeOutlined,
  FilePdfOutlined
} from '@ant-design/icons';
import { ISupplementaryMaterial } from 'interfaces/supplementary_materials';
import { MenuProps } from 'antd/lib';
import { SupplementaryMaterialItem } from 'components/forms/SupplementaryMaterialForm';
import { download_url as downloadSupplementaryMaterials } from 'requests/supplementary-materials';
import { formatDate } from 'services/date';
import { useCallback, useState } from 'react';
import { useDelete } from '@refinedev/core';

const { confirm } = Modal;

export interface TableRowItemProps {
  file: SupplementaryMaterialItem;
  getSavedItems: () => void;
  previewModalShow: (fileUrl: string) => void;
}

export const TableRowItem = ({
  file,
  getSavedItems,
  previewModalShow
}: TableRowItemProps) => {
  const [hovered, setHover] = useState(false);
  const { mutateAsync: deleteSupplementaryMaterial } =
    useDelete<ISupplementaryMaterial>();

  const showConfirm = () => {
    confirm({
      title: 'Tem certeza que deseja excluir este material?',
      icon: <ExclamationCircleOutlined className="!text-[#F5222D]" />,
      content: 'Esta ação não poderá ser desfeita!',
      okButtonProps: { type: 'primary' },
      okText: 'Excluir',
      cancelText: 'Cancelar',
      okType: 'danger',
      onOk() {
        handleDelete(file.id!);
      }
    });
  };

  const defaultDropdownItems: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <Button
          className="!text-[#000000E0]"
          type="link"
          icon={<EyeOutlined className="!text-[#00000073]" />}
          onClick={() => previewModalShow(file.preview_url)}
        >
          Visualizar Arquivo
        </Button>
      )
    },
    {
      key: '2',
      label: (
        <Button
          className="!text-[#000000E0]"
          type="link"
          icon={<DownloadOutlined className="!text-[#00000073]" />}
          onClick={() => handleDownload(file.id!)}
        >
          Baixar Arquivo
        </Button>
      )
    },
    {
      key: '3',
      label: (
        <Button
          className="!text-[#000000E0]"
          type="link"
          icon={<DeleteOutlined className="!text-[#00000073]" />}
          onClick={showConfirm}
        >
          Excluir Arquivo
        </Button>
      )
    }
  ];

  const handleDownload = useCallback((id: string) => {
    downloadSupplementaryMaterials(id).then(res => window.open(res.url));
  }, []);

  const handleDelete = async (id: string) => {
    await deleteSupplementaryMaterial({
      resource: 'supplementary_materials',
      id,
      successNotification: () => ({
        type: 'success',
        description: 'Tudo certo!',
        message: 'Material suplementar excluído com sucesso!'
      }),
      errorNotification: () => {
        return {
          description: 'Algo deu errado',
          message: 'Não foi possível excluído o material suplementar',
          type: 'error'
        };
      }
    });
    getSavedItems();
  };

  return (
    <>
      <Space className="group flex w-full py-4 px-2 justify-between hover:bg-neutral-100">
        <Space className="!gap-4">
          <Space
            direction="vertical"
            className="flex rounded-md w-[56px] h-[56px] p-2 bg-[#a855f7]/10 justify-center items-center !gap-0"
          >
            <FilePdfOutlined className="text-[#9333ea] text-2xl" />
          </Space>
          <Space direction="vertical">
            <Typography.Title level={5} className="!m-0">
              {file.name}
            </Typography.Title>
            <Space>
              <Typography.Text className="!m-0 !text-[#000000A6]">
                {formatDate(file.created_at, 'DD/MM/YYYY')}
              </Typography.Text>
            </Space>
          </Space>
        </Space>

        <Space>
          <Dropdown
            menu={{ items: defaultDropdownItems } as MenuProps}
            placement="bottomLeft"
            onOpenChange={setHover}
            className={clsx(
              'opacity-0 group-hover:opacity-100 group-active:opacity-100',
              hovered && 'opacity-100'
            )}
          >
            <Button icon={<EllipsisOutlined />} />
          </Dropdown>
        </Space>
      </Space>
      <Divider className="w-full !m-0" />
    </>
  );
};
