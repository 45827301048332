import { IResource } from 'interfaces/resources';
import { generateResources } from 'utils/GenerateResouces';

const resource: IResource = {
  name: 'professor_area/interactive_classes',
  label: 'Aulas interativas',
  actions: ['list', 'show', 'create', 'edit', 'delete'],
  parentName: 'professor_area',
  onlyPermission: true
};

export default generateResources(resource);
