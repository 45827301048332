import React from 'react';
import { Empty, Pagination, Typography } from 'antd';
import { IProfessorAreaPosts } from 'interfaces/professor_area/forums';
import { PostItem } from './PostItem';
import { Stack } from '@qcx/ui';

export const TopicList: React.FC<{
  data: IProfessorAreaPosts[];
  total: number;
  pageSize: number;
  currentPage: number;
  onPageChange: (page: number, pageSize?: number) => void;
  refetch: () => void;
}> = ({ data, total, pageSize, currentPage, onPageChange, refetch }) => {
  return (
    <Stack flow="column" className="w-full">
      <Typography.Title level={4}>Registro da conversa</Typography.Title>
      {data.length === 0 && (
        <Stack className="items-center justify-center my-8">
          <Empty description="Não há posts nesse fórum" />
        </Stack>
      )}
      <Stack flow="column" className="my-8 gap-8">
        {data.map(post => (
          <PostItem
            key={post.id}
            postData={post}
            refetch={refetch}
            enableDelete
          />
        ))}
      </Stack>
      <Pagination
        className="self-end mt-4 text-center"
        hideOnSinglePage
        total={total}
        pageSize={pageSize}
        current={currentPage}
        onChange={onPageChange}
      />
    </Stack>
  );
};
