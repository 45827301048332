import { ClassroomList, ClassroomShow } from 'pages/teacher_space/classrooms';
import { IResource } from 'interfaces/resources';
import { generateResources } from 'utils/GenerateResouces';

const resource: IResource = {
  name: 'professor_area/classrooms',
  label: 'Turmas',
  list: ClassroomList,
  show: ClassroomShow,
  actions: ['list', 'show'],
  parentName: 'professor_area',
  route: 'professor_area/classrooms'
};

export default generateResources(resource);
