import { Modal, ModalProps } from 'antd';

interface ContentPreviewModalProps {
  modalProps: ModalProps;
  previewUrl: string;
}

export const ContentPreviewModal = ({
  modalProps,
  previewUrl
}: ContentPreviewModalProps) => {
  return (
    <Modal
      {...modalProps}
      className="!w-[80vw]"
      title="Material complementar"
      okButtonProps={{ className: 'hidden' }}
      cancelText="Voltar"
    >
      <iframe src={previewUrl} className="w-full h-[70vh]" />
    </Modal>
  );
};
