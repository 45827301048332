import {
  CalendarOutlined,
  MessageOutlined,
  RightOutlined
} from '@ant-design/icons';
import { IProfessorAreaForum } from 'interfaces/professor_area/forums';
import { Space, Typography } from 'antd';
import { SyntheticEvent } from 'react';
import { formatDate } from 'services/date';
import { useNavigation } from '@refinedev/core';

export const ForumsItem = (forum: IProfessorAreaForum) => {
  const { showUrl, push } = useNavigation();

  const handleNavigate = (e: SyntheticEvent<HTMLDivElement>): void => {
    e?.preventDefault();

    const uri = showUrl('professor_area/forums', forum.id);
    push(uri);
  };

  return (
    <Space
      size="small"
      className="group w-full p-4 hover:cursor-pointer hover:bg-neutral-100 hover:rounded-lg justify-between transition"
      onClick={handleNavigate}
    >
      <Space direction="vertical" className="!gap-0">
        <Typography.Text className="!text-md">{forum.title}</Typography.Text>

        <Space className="gap-4">
          <Typography.Text type="secondary">
            {forum.author.name}
          </Typography.Text>
          <Space className="gap-1">
            <MessageOutlined />
            <Typography.Text type="secondary">
              {forum.posts_count ?? 0}
            </Typography.Text>
          </Space>
          <Space className="gap-1">
            <CalendarOutlined />
            <Typography.Text type="secondary">
              {formatDate(forum.created_at, 'DD/MM/YYYY')}
            </Typography.Text>
          </Space>
        </Space>
      </Space>

      <RightOutlined className="opacity-0 group-hover:opacity-100 transition" />
    </Space>
  );
};
