export const getInitialsName = (fullName: string) => {
  if (!fullName) {
    return '';
  }

  const names = fullName.trim().split(' ');

  if (names.length < 2) {
    return names[0].charAt(0).toUpperCase();
  }

  const firstInitial = names[0].charAt(0).toUpperCase();
  const secondInitial = names[1].charAt(0).toUpperCase();

  return firstInitial + secondInitial;
};
