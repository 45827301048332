import React from 'react';
import { Button, Space, Spin, Typography } from 'antd';
import { CreateTopicModal } from 'components/modal/professor_area/forums/CreateTopicModal';
import { CrudFilters, HttpError, useModal } from '@refinedev/core';
import { ForumList } from './components/ForumList';
import { IForum } from 'interfaces/forum';
import { IProfessorAreaClassroom } from 'interfaces/professor_area_classrooms';
import { PlusOutlined } from '@ant-design/icons';
import { SearchFilters } from './components/SearchFilters';
import { useTable } from '@refinedev/antd';

export interface IFilter {
  topic: string;
  status: string;
}

export const TabForum: React.FC<{
  classroom: IProfessorAreaClassroom;
}> = ({ classroom }) => {
  const createTopicModalProps = useModal();
  const {
    tableQueryResult: { data, isLoading, refetch },
    current,
    setCurrent,
    pageSize,
    searchFormProps
  } = useTable<IForum, HttpError, IFilter>({
    resource: 'professor_area/forums',
    initialPageSize: 10,
    onSearch: ({ topic, status }) => {
      const filters: CrudFilters = [];

      filters.push(
        {
          field: 'filter[by_title]',
          operator: 'eq',
          value: topic
        },
        {
          field: 'filter[by_status]',
          operator: 'eq',
          value: status
        }
      );
      return filters;
    }
  });

  const forums = data?.data as IForum[];

  return (
    <div>
      <SearchFilters searchFormProps={searchFormProps} />
      <Space className="w-full justify-between items-center mb-4">
        <Typography.Title level={5} className="!m-0">
          Tópicos da turma
        </Typography.Title>
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => createTopicModalProps.show()}
        >
          Novo tópico
        </Button>
      </Space>
      {isLoading && <Spin size="large" />}
      {forums?.length > 0 && (
        <ForumList
          data={forums}
          total={data!.total}
          pageSize={pageSize}
          currentPage={current}
          onPageChange={setCurrent}
          refetch={refetch}
        />
      )}
      <CreateTopicModal
        modalProps={createTopicModalProps}
        classroom={classroom}
      />
    </div>
  );
};
