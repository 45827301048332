import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '../../tailwind.config';
import type { ThemeConfig } from 'antd';

const fullConfig = resolveConfig(tailwindConfig);
const colors = fullConfig.theme.colors;

export const defaulColors = {
  primary: colors.purple[600],
  primaryHover: colors.purple[500],
  success: colors.emerald[500],
  error: colors.red[500],
  warning: colors.yellow[500],
  link: colors.blue[500],
  text: colors.gray[900],
  textMuted: colors.gray[400],
  border: colors.gray[300]
};

export const themeConfig: ThemeConfig = {
  token: {
    colorTextHeading: defaulColors.text,
    colorText: defaulColors.text,
    colorTextSecondary: defaulColors.text,
    colorPrimary: defaulColors.primary,
    colorSuccess: defaulColors.success,
    colorError: defaulColors.error,
    colorWarning: defaulColors.warning,
    colorLink: defaulColors.link,
    colorTextDisabled: defaulColors.textMuted,
    colorBorder: defaulColors.border,
    colorTextLabel: defaulColors.text,
    colorPrimaryBg: defaulColors.primary,
    borderRadius: 6,
    boxShadow: `0 2px 8px ${colors.gray[200]}`,
    fontFamily:
      'Roboto,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji'
  },
  components: {
    Layout: {
      siderBg: '#342C49',
      triggerBg: 'rgba(0, 0, 0, 0.5)',
      colorBgContainer: '#ffffff'
    },
    Menu: {
      popupBg: '#342C49',
      colorHighlight: '#ffffff',
      itemColor: '#ffffff',
      itemSelectedColor: '#ffffff',
      itemSelectedBg: 'transparent',
      subMenuItemBg: 'trasparent',
      colorBgTextActive: 'transparent',
      itemHoverBg: 'transparent',
      itemHoverColor: '#ffffff',
      itemActiveBg: 'transparent'
    },
    Switch: {
      colorPrimary: defaulColors.primary,
      colorPrimaryBg: defaulColors.primary
    },
    Radio: {
      buttonBg: fullConfig.theme.colors.white,
      colorPrimary: defaulColors.primary,
      colorPrimaryHover: defaulColors.primaryHover,
      colorPrimaryActive: defaulColors.primary
    },
    Button: {
      primaryShadow: 'unset'
    },
    Progress: {
      defaultColor: defaulColors.primary
    }
  }
};
