import { Button, FormProps, Modal, ModalProps, Space } from 'antd';
import { FormNoticeBoard } from './Form';
import { IProfessorAreaNoticeBoardPost } from 'interfaces/professor_area/notice_boards';
import { useCallback } from 'react';

interface ModalCreateNoticeBoardProps {
  modalProps: ModalProps;
  formProps: FormProps<IProfessorAreaNoticeBoardPost>;
  classroomId: string;
  isLoading: boolean;
  onCloseModal: () => void;
}

export const ModalCreateNoticeBoard = ({
  formProps,
  modalProps,
  classroomId,
  isLoading,
  onCloseModal
}: ModalCreateNoticeBoardProps) => {
  const onFinish = useCallback(
    async (formData: IProfessorAreaNoticeBoardPost) => {
      await formProps.form?.validateFields();

      const data: IProfessorAreaNoticeBoardPost = {
        ...formData,
        kind: 'classroom',
        classroom_ids: [classroomId]
      };

      formProps?.onFinish?.(data);
    },
    [formProps, classroomId]
  );

  return (
    <Modal
      {...modalProps}
      title="Novo aviso"
      width={700}
      style={{ top: 20, paddingBottom: 20 }}
      centered
      afterClose={() => {
        formProps.form?.resetFields();
      }}
      footer={
        <Space className="justify-self-end">
          <Button onClick={onCloseModal} size="large">
            Cancelar
          </Button>
          <Button
            htmlType="submit"
            type="primary"
            size="large"
            loading={isLoading}
            onClick={formProps.form?.submit}
          >
            Publicar aviso
          </Button>
        </Space>
      }
    >
      <FormNoticeBoard
        formProps={formProps}
        isShow={false}
        onFinish={onFinish}
      />
    </Modal>
  );
};
