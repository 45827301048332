import { IResource } from 'interfaces/resources';
import { generateResources } from 'utils/GenerateResouces';

const resource: IResource = {
  name: 'professor_area/classroom_enrollments',
  label: 'Alunos',
  actions: ['show', 'list', 'edit'],
  parentName: 'professor_area',
  onlyPermission: true
};

export default generateResources(resource);
