import { Card, Space, Typography } from 'antd';
import { Fragment, useMemo } from 'react';
import { InteractiveClassItem } from './InteractiveClassItem';
import { LaptopOutlined } from '@ant-design/icons';
import { Link, useParams } from 'react-router-dom';
import { ShowClassModal } from 'components/modal/professor_area/classroom/ShowClassModal';
import { useList } from '@refinedev/core';
import { useModalForm } from '@refinedev/antd';
import type { IProfessorAreaInteractiveClass } from 'interfaces/professor_area_interactive_class';

export const InteractiveClassList = () => {
  const params = useParams();
  const classroomId = params.id;

  const { data, refetch } = useList<IProfessorAreaInteractiveClass>({
    resource: 'professor_area/interactive_classes',
    filters: [
      {
        field: 'filter[by_classroom_id]',
        operator: 'eq',
        value: classroomId
      },
      {
        field: 'filter[by_status]',
        operator: 'eq',
        value: 'created'
      }
    ]
  });

  const {
    modalProps: showModalProps,
    formProps: showFormProps,
    queryResult: showQuery,
    show: showModal,
    close: closeModal
  } = useModalForm<IProfessorAreaInteractiveClass>({
    resource: 'professor_area/interactive_classes',
    action: 'edit',
    redirect: false
  });

  const hasInteractiveClasses = useMemo(() => {
    if (data) {
      return data.total > 0;
    }
  }, [data]);

  const handleShowModal = (id: string) => {
    showModal(id);
  };

  return (
    <>
      <Card
        title={
          <Space className="!items-center">
            <LaptopOutlined className="text-xl text-neutral-400" />
            <Typography.Text className="text-md font-semibold">
              Próximas aulas interativas
            </Typography.Text>
          </Space>
        }
        extra={<Link to={{ search: '?tab=interactiveClass' }}>Ver todas</Link>}
        styles={{
          body: {
            padding: 8
          }
        }}
      >
        <div className="w-full flex flex-col max-h-[180px] overflow-y-auto overflow-x-hidden">
          {!hasInteractiveClasses && (
            <Typography.Text className="text-center py-4 text-neutral-500">
              Nenhuma aula interativa encontrada
            </Typography.Text>
          )}

          {data?.data.map((item: IProfessorAreaInteractiveClass) => (
            <Fragment key={item.id}>
              <InteractiveClassItem
                key={item.id}
                {...item}
                onShowModal={handleShowModal}
              />
              <hr className="w-[calc(100%-24px)] mx-auto" />
            </Fragment>
          ))}
        </div>
      </Card>

      {!showQuery?.isLoading && (
        <ShowClassModal
          isEdit={false}
          formProps={showFormProps}
          modalProps={showModalProps}
          closeModal={closeModal}
          refetch={refetch}
        />
      )}
    </>
  );
};
